import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Row, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import { useDispatch, useSelector } from "react-redux";
import { changecostdeliverychallan, changedeliverychallandiscount, changedeliverychallansqpbqty, changedeliverychallantext, getdeliverychallandatatoload, removefromcartfromdeliverychallan, deliverychallanaddtocart, changeunitofMeasurementdeliverychallan, deliverychallancalmeasurement, changedeliverychallansqpbqtykg } from "../../redux/Action/DeliveryChallan";
import ProductService from "../services/ProductService";
import Swal from "sweetalert2";

export default function Create() {
    const [distributordata12, setdistributordata12] = useState([])
    const [disword12, setdisword12] = useState(encodeURIComponent(' '))

    const { id } = useParams();
    const [deliveryAddress, setdelval] = useState([]);

    const [warehousedata, setwarehousedata] = useState('');
    const [customerdata, setcustomerdata] = useState('');
    const [customersubcontactdata, setcustomersubcontactdata] = useState('');
    const [dcdate, setdcdate] = useState('');
    const [type, settype] = useState('');
    const [status, setstatus] = useState('');
    const [taxtype, settaxtype] = useState('');
    const [buyerorderno, setbuyerorderno] = useState('');
    const [buyerorderdate, setbuyerorderdate] = useState('');
    const [totalnopkg, settotalnopkg] = useState('');
    const [termsofdelivery, settermsofdelivery] = useState('');
    const [dispatchedthrough, setdispatchedthrough] = useState('');
    const [destination, setdestination] = useState('');
    const [deliverynotno, setdeliverynotno] = useState('');
    const [irnno, setirnno] = useState('');
    const [ackno, setackno] = useState('');
    const [ackdate, setackdate] = useState('');
    const [msmeno, setmsmeno] = useState('');
    const [productdata, setproductdata] = useState([]);
    const [branchdata, setbranchdata] = useState([]);
    const [branch, setbranch] = useState('');
    const [shippingcharge, setShipping] = useState(0);
    const [round, setRound] = useState(0);
    const [bstateid, setbstateid] = useState('');
    const [dstateid, setdstateid] = useState('');
    const [paymentTerms, setpaymentTerms] = useState('');
    const [disdel, setdel] = useState([]);
    const [udyamno, setudyamno] = useState('');
    const [gstno, setgstno] = useState('');
    const [igstoutput, setigstoutput] = useState('');
    const [bankname,setbankname] = useState('');
    const [accountno,setaccountno] = useState('');
    const [branchname,setbranchname] = useState('');
    const [ifsccode,setifsccode] = useState('');
    const [retailer, setretailer] = useState('');
    const [retailerdata, setretailerdata] = useState('');
    // const [deliveryAddress,setdelval]=useState([]);
    // const [selectedOption, setSelectedOption] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    
    const dispatch = useDispatch();
    const dcItems = useSelector((state) => state.deliverychallandata);
    const salesOrderItems = useSelector((state) => state.deliverychallandata);
    let gross_Amount = dcItems.length && dcItems.map(item => Math.round(item.total)).reduce((prev, next) => Math.round(prev - (-next)));
    let gst = dcItems.length && dcItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
    // console.log("mello",dcItems)

    let aa = [];
    dcItems.map(user => {
        let igst_value = user.igst;
        let total_value = user.total;
        let total_amou = ((Number(shippingcharge) / Number(gross_Amount)) * Number(total_value));
        let add_amou = Number(total_amou) - (-Number(total_value));
        let final_amou = ((Number(add_amou) / 100) * Number(igst_value)).toFixed(2);
        aa.push(final_amou)

        // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
    })
    let total_per = aa.length && aa.map(user => user).reduce((prev, next) => (prev - (-next)));

    let grossAmount = Number(gross_Amount) + Number(shippingcharge);
    let grandTotal = Number(gross_Amount) + Number(shippingcharge) + Number(round) + Number(total_per);

    let igstTax = Number(total_per);
    let cgstTax = (Number(total_per) / 2);
    let sgstTax = (Number(total_per) / 2);

    const navigate = useNavigate();
    // console.log("mello",dcItems)
    function roundData(e) {
        if (e) {

            let gross_Amount = dcItems.length && dcItems.map(item => Math.round(item.total)).reduce((prev, next) => Math.round(prev - (-next)));
            let gst = dcItems.length && dcItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));

            let aa = [];
            dcItems.map(user => {
                let igst_value = user.igst;
                let total_value = user.total;
                let total_amou = ((Number(shippingcharge) / Number(gross_Amount)) * Number(total_value));
                let add_amou = Number(total_amou) - (-Number(total_value));
                let final_amou = ((Number(add_amou) / 100) * Number(igst_value)).toFixed(2);
                aa.push(final_amou)

                // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
            })
            let total_per = aa.length && aa.map(user => user).reduce((prev, next) => (prev - (-next)));


            let grossAmount = Number(gross_Amount) + Number(shippingcharge);
            let grandTotal = Number(gross_Amount) + Number(shippingcharge) + Number(total_per);
            let grand_Total = Math.ceil(grandTotal);

            if (grand_Total > grandTotal) {
                let rou_nd = (Number(grand_Total) - Number(grandTotal)).toFixed(2);
                setRound(rou_nd);
            } else {
                setRound(0)
            }


        } else if (e == '') {
            setRound(0)
        }
    }

const [Data, setData] = useState([])
    let getSalesOrderData = () => {

        axios.get(BASE_URL + 'so/' + id, {
            headers: authHeader()
        }).then(res => {
            setdel(res.data.distributor.deliveryAddress)
            setSelectedOption(res.data.deliveryAddress)
setvoucher(res.data.voucherid)
setData(res.data)
            setdelval(res.data.deliveryAddress)
            console.log("mello", res.data.termsofdelivery);
            setwarehousedata(res.data.warehouse);
            setcustomerdata(res.data.distributor == null ? null : res.data.distributor);
            setretailer(res.data.retailer == null ? null : { "id": res.data.retailer.id });
            setretailerdata(res.data.retailer == null ? null : res.data.retailer);
            setbuyerorderno(res.data.buyerorder);
            setdispatchedthrough(res.data.dispatchedthrough);
            settermsofdelivery(res.data.termsofdelivery);
            setcustomersubcontactdata(res.data.customersubcontactdata);
            setdcdate(res.data.sodate);
            setpaymentTerms(res.data.paymentTerms);
            settype(res.data.type);
            setstatus(res.data.status);
            settaxtype(res.data.taxtype);
            setbranch({ "id": res.data.branch.id });
            setbranchdata(res.data.branch.branchname);
            setbstateid(res.data.branch.states.id);
            setShipping(res.data.shippingcharge);
            setRound(res.data.roundingofvalue);
            setdstateid(res.data.distributor == null ? res.data.retailer.stateid : res.data.distributor.stateid);
            
        })
    };

    let date_value = new Date(dcdate);
    const defaultValue = date_value.toLocaleDateString('en-CA');

    /* get Product All */
    const getproductdata = () => {
        axios.get(BASE_URL + 'product/page/0/30/asc/id/%20', {
            headers: authHeader()
        }).then(res => {

            setproductdata((res.data.Index).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
        })
    };

    const customFilter = (e) => {
        let name = e;
        axios.get(BASE_URL + 'product/page/0/100/asc/id/' + name, {
            headers: authHeader()
        }).then(res => {
            setproductdata((res.data.data).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })))
        })
    };

    useEffect(() => {

        getSalesOrderData();
        getproductdata();
        dispatch(getdeliverychallandatatoload(id));

    }, []);

    let addData = (e) => {
        let name = e.value;
        //  console.log("mello",customerdata)
        let filteredItems = dcItems.filter(item => item.product.id == name);
        if (filteredItems.length == "0") {
            dispatch(deliverychallanaddtocart(name, ({
                "schemeboxProductDiscount": customerdata == null ? retailerdata.schemeboxProductDiscount : customerdata.schemeboxProductDiscount,
                "schemekgProductDiscount": customerdata == null ? retailerdata.schemekgProductDiscount : customerdata.schemekgProductDiscount,
                "schemecorporateProductDiscount": customerdata == null ? retailerdata.schemecorporateProductDiscount : customerdata.schemecorporateProductDiscount,
                "schemecookerProductDiscount": customerdata == null ? retailerdata.schemecookerProductDiscount : customerdata.schemecookerProductDiscount,
                "schemenoshProductDisocunt": customerdata == null ? retailerdata.schemenoshProductDisocunt : customerdata.schemenoshProductDisocunt,
                "kgProductDiscount": customerdata == null ? retailerdata.kgProductDiscount : customerdata.kgProductDiscount,
                "boxProductDiscount": customerdata == null ? retailerdata.boxProductDiscount : customerdata.boxProductDiscount,
                "corporaetProductDiscount": customerdata == null ? retailerdata.corporaetProductDiscount : customerdata.corporaetProductDiscount,
                "cookerProductDiscount": customerdata == null ? retailerdata.cookerProductDiscount : customerdata.cookerProductDiscount,
                "noshProductDiscount": customerdata == null ? retailerdata.noshProductDiscount : customerdata.noshProductDiscount,
            })));
            setShipping("0");
        } else {
            Swal.fire({
                title: 'This Product is Already Added',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: 'red'
            })
        }
    };
    /* get Shipping Charges */
    let settingship = (e) => {
        if (e == "") {

            setShipping("");
        } else {
            setShipping(e);
        }

    };

    /* get Round Value */
    const settinground = (e) => {

        if (e == "") {

            setRound("");
        } else {


            setRound(e);
        }

    };

    const SGSTcheck = () => {
        let aa = 0

        if (taxtype == "withtax") {

            if (bstateid === dstateid) {

                aa = sgstTax;

            } else {
                aa = 0;
            }

        } else {
            aa = 0;
        }

        return aa;

    }

    const cgstcheck = () => {

        let bb = 0

        if (taxtype == "withtax") {

            if (bstateid === dstateid) {

                bb = cgstTax;

            } else {

                bb = 0;

            }

        } else {

            bb = 0;

        }
        return bb;
    }
    const [selectedOption, setSelectedOption] = useState(null);

    const igstcheck = () => {
        let cc = 0;

        if (taxtype == "withtax") {
            if (bstateid === dstateid) {
                cc = 0;
            } else {
                cc = igstTax;
            }
        } else {
            cc = 0;
        }
        return cc;
    }

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);

        setdelval(selectedOption)
    };


    const submitdata = () => {
        setIsLoading(true);
        let warehouse = { "id": warehousedata.id };
        let distributor = { "id": customerdata.id };
        let salesorderId = id;
        let igst = igstTax;
        let cgst = cgstTax;
        let sgst = sgstTax;
        let grandtotal = grandTotal;
        let roundofvalue = round;

        if(customerdata !== null){

            axios.post(BASE_URL + 'dc/' + id, {
                warehouse, distributor, type, status,voucherid, taxtype, buyerorderno, buyerorderdate, totalnopkg, termsofdelivery, deliverynotno, dcdate, dispatchedthrough,
                destination, irnno, ackno, ackdate, msmeno, salesorderId, dcItems, salesOrderItems, udyamno, gstno, igstoutput,bankname,accountno,branchname,ifsccode,
                igst, cgst, deliveryAddress, sgst, grandtotal, grossAmount, shippingcharge, roundofvalue, branch, paymentTerms
            }, {
                headers: authHeader()
            }).then(res => {
                navigate('/DeliveryChallanTable/1')
            }).catch(error => {
                console.error('Error adding  :', error);
                if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `
                    <div>
                      <p id="issueText">Why do I have this issue?</p>
                      <ul>
                  
                      <li id="typer1">You might have an internet issue</li>
                      </ul>
                    </div>
                  `,
                  didOpen: () => {
                    applyFontSizeTransition('issueText');
                  }
                });
              }
              }).finally(() => {
                setIsLoading(false); 
              });
        } else if(retailerdata !== null){
            axios.post(BASE_URL + 'dc/' + id, {
                warehouse, retailer, type, status, taxtype,voucherid, buyerorderno, buyerorderdate, totalnopkg, termsofdelivery, deliverynotno, dcdate, dispatchedthrough,
                destination, irnno, ackno, ackdate, msmeno, salesorderId, dcItems, salesOrderItems, udyamno, gstno, igstoutput,bankname,accountno,branchname,ifsccode,
                igst, cgst, deliveryAddress, sgst, grandtotal, grossAmount, shippingcharge, roundofvalue, branch, paymentTerms
            }, {
                headers: authHeader()
            }).then(res => {
                navigate('/DeliveryChallanTable/1')
            }).catch(error => {
                console.error('Error adding  :', error);
                if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `
                    <div>
                      <p id="issueText">Why do I have this issue?</p>
                      <ul>
                  
                      <li id="typer1">You might have an internet issue</li>
                      </ul>
                    </div>
                  `,
                  didOpen: () => {
                    applyFontSizeTransition('issueText');
                  }
                });
              }
              }).finally(() => {
                setIsLoading(false); 
              });
        }
    }
    const [voucherid, setvoucher] = useState([])
    const Onchangevoucher = (e) => {
        setvoucher(e.label)
    }

useEffect(() => {
 
    axios.get(BASE_URL + `voucher/page/0/100/desc/id/${disword12.length ? disword12 : encodeURIComponent(' ')}`, {
        headers: authHeader()
    }).then(response => {
        if (response.data.data == undefined) {
            setdistributordata12(response.data.Index.map(use => ({ value: use.voucherseries, label: use.voucherseries })));
        } if (response.data.Index == undefined) {
            setdistributordata12(response.data.data.map(use => ({ value: use.voucherseries, label: use.voucherseries })));

        }
     })


}, [disword12])


    return (

        <MDBCard className="company">
            <h1>DC Create</h1>
            <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/DeliveryChallanTable/1`}><Button>Back</Button></Link>

            <Form className="product1">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>DC Data</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Branch Name</Form.Label>
                                    <Form.Control type="text" value={branchdata} disabled />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Warehouse Name</Form.Label>
                                    <Form.Control type="text" value={warehousedata.name} readOnly />
                                </Form.Group>
                                {customerdata !== null &&
                                    <Form.Group as={Col} md="4" controlId="formBasicName">
                                        <Form.Label className="label">Customer Name</Form.Label>
                                        <Form.Control type="text" value={customerdata.tradeName} readOnly />
                                    </Form.Group>
                                }
                                {retailerdata !== null &&
                                    <Form.Group as={Col} md="4" controlId="formBasicName">
                                        <Form.Label className="label">Customer Name</Form.Label>
                                        <Form.Control type="text" value={retailerdata.tradeName} readOnly />
                                    </Form.Group>
                                }
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                <Form.Label className="label">Contact Name</Form.Label>
                                <Form.Control type="text" value={customerdata.name} readOnly  />
                            </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue} onChange={(e) => setdcdate(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Payment</Form.Label>
                                    <Form.Control type='text' value={paymentTerms} name="paymentTerms" onChange={(e) => setpaymentTerms(e.target.value)} autoComplete="off" />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Type</Form.Label>
                                    <Form.Select value={type} onChange={(e) => settype(e.target.value)}>
                                        <option value="b2b">B2B</option>
                                        <option value="b2c">B2C</option>
                                    </Form.Select>
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Status</Form.Label>
                                    <Form.Select value={status} onChange={(e) => setstatus(e.target.value)}>
                                        <option value='true'>Open</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                <Form.Label className="label">Vouchers Series</Form.Label>
                                <Form.Control type='text' value={voucherid} name="voucherid" onChange={(e) => setpaymentTerms(e.target.value)} autoComplete="off" />

                                {/* <Select options={customerData} onChange={(e) => onChangeCustomer(e)} /> */}
                                    {/* <Select options={distributordata12} onInputChange={(inputValue) => setdisword12(inputValue)} onChange={(options) => Onchangevoucher(options)} /> */}

                                </Form.Group>

                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Select value={taxtype} onChange={(e) => settaxtype(e.target.value)}>
                                        <option>--Select--</option>
                                        <option value='withtax'>With Tax</option>
                                        <option value='withouttax'>Without Tax</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Buyer’s Order No</Form.Label>
                                    <Form.Control type="text" value={buyerorderno} name="buyerorderno" onChange={(e) => setbuyerorderno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Buyer’s Order Date</Form.Label>
                                    <Form.Control type="date" onChange={(e) => setbuyerorderdate(e.target.value)} />

                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Total No. of PKGs</Form.Label>
                                    <Form.Control type="text" onChange={(e) => settotalnopkg(e.target.value)} />
                                </Form.Group>

                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Delivery Note No</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setdeliverynotno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Dispatch Through</Form.Label>
                                    <Form.Control type="text" value={dispatchedthrough} name="dispatchedthrough" onChange={(e) => setdispatchedthrough(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Destination</Form.Label>
                                    <Form.Control type="text" value={destination} onChange={(e) => setdestination(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">TERMS OF DELIVERY</Form.Label>
                                    <Form.Control type="text" value={termsofdelivery} name="termsofdelivery" onChange={(e) => settermsofdelivery(e.target.value)} />
                                </Form.Group>

                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="1">
                        <Accordion.Header>DELIVERY ADDRESS</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Name</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Address</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">GST/UIN</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">State Name</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>



                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Phone No : -</Form.Label>
                                    <span>&nbsp;{customerSubContactsdata == "" ? customerAll.phonenumber : customerSubContactsdata.phoneno}</span> 
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Email Id : -</Form.Label>
                                     <span> {customerSubContactsdata == "" ? customerAll.email : customerSubContactsdata.email}</span> 
                                </Form.Group>

                            </Row>
                        </Accordion.Body>
                    </Accordion.Item> */}
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>BILLING ADDRESS</Accordion.Header>
                        <Accordion.Body>
                            {/* <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Name</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Address</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">GST/UIN</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">State Name</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Phone No : -</Form.Label>
                                    <span>&nbsp;{customerSubContactsdata == "" ? customerAll.phonenumber : customerSubContactsdata.phoneno}</span>
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Email Id : -</Form.Label>
                                    <span> {customerSubContactsdata == "" ? customerAll.email : customerSubContactsdata.email}</span>
                                </Form.Group>
                            </Row> */}
                            <b><span className="">Delivery Address</span></b> <br></br> <br></br>
                            <Row className="mb-1">
                                {/* <select className="form-select dd">
                                <option className="extrvagnze">select Address</option>
                               
                                {
                                    disdel.map((item)=>{
                                        return<>
                                          <option className="extrvagnze">Address:{item.delivery_address} ,State:-{item.state} &nbsp;,Pin-code:-{item.pincode}</option>
                                        </>
                                    })
                                }
                               </select> */}
                                {/* <Select
      options={options}
      onChange={handleSelectChange}
      filterOption={customFilter11}
      placeholder="Select Address"
      value={selectedOption}
    /> */}
                                <select className="form-control" value={selectedOption} onChange={(e) => handleSelectChange(e.target.value
                                )}>
                                    <option>Select Delivery Address</option>
                                    {
                                        disdel.map((item) => {
                                            return <>
                                                <option value={item.id}>{item.delivery_address}</option>
                                            </>
                                        })
                                    }
                                </select>

                            </Row>


                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">IRN NO</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setirnno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">ACK NO</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setackno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">ACK DATE</Form.Label>
                                    <Form.Control type="date" onChange={(e) => setackdate(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">MSME NO</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setmsmeno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">UDYAM NO</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setudyamno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">GST NO</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setgstno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">IGST OUTPUT</Form.Label>
                                    <Form.Control type="text" name="igstoutput" onChange={(e) => setigstoutput(e.target.value)} />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Rounding off</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group> */}

                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="5">
                        <Accordion.Header>Account</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">

                                <h1>Company Bank Details</h1>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Bank Name</Form.Label>
                                    <Form.Control type="text" name="bankname" onChange={(e)=>setbankname(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Account Amount</Form.Label>
                                    <Form.Control type="text" name="accountno" onChange={(e)=>setaccountno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Branch Name</Form.Label>
                                    <Form.Control type="text" name="branchname" onChange={(e)=>setbranchname(e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">IFSC Code</Form.Label>
                                    <Form.Control type="text" name="ifsccode" onChange={(e)=>setifsccode(e.target.value)}/>
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br></br>
                <Row className="mb-1">
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Product</Form.Label>
                        <Select options={productdata} onChange={(e) => addData(e)} onInputChange={(e) => customFilter(e)} />
                    </Form.Group>
                </Row>
                <br></br>
                <Table striped responsive hover size="sm">
                    <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th>PRODUCT&nbsp;NAME</th>
                            <th>UOM</th>
                            <th >QTY(PCS)</th>
                            <th >QTY(KG)</th>
                            <th >Rate</th>
                            <th>UOM</th>
                            <th >Trade&nbsp;Discount</th>
                            <th>Gross&nbsp;Amount&nbsp;(₹)</th>
                            <th >Scheme&nbsp;Discount&nbsp;%</th>
                            <th >NET&nbsp;AMOUNT&nbsp;(₹)</th>
                            <th >GST&nbsp;Value</th>
                            <th >Amount</th>
                            <th >Action</th>
                        </tr>
                    </thead>
                    <tbody style={{ overflow: "scroll" }}>
                        {dcItems.map(item =>
                            <tr key={item.product.id}>
                                <td>
                                    <Form.Group as={Col} md="12" >
                                        {/* <Form.Control type="text" value={(item.productName ? item.productName : item.product.productName)} size="sm" /> */}
                                        <textarea type="text" className="form-control textcontrol" value={(item.productName ? item.productName : item.product.productName)} size="sm" ></textarea>

                                    </Form.Group>
                                    <Form.Control as="textarea" onChange={(e) => { dispatch(changedeliverychallantext(e.target.value, item)) }} value={item.text} rows={2} className='form-control' />
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Select disabled={item.unitofmeasurement == "box" ? true : item.productType !== "kgproduct" ? true : false} value={item.unitofmeasurement} onChange={(e) => { dispatch(changeunitofMeasurementdeliverychallan(e.target.value, item, item.unitofmeasurement)); }} size="sm">
                                            <option>{item.unitofmeasurement == "box" ? "PCS" : item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                            <option value="pcs">PCS</option>
                                            <option value="kg">KG</option>
                                        </Form.Select>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changedeliverychallansqpbqty(e.target.value, item, taxtype, item.unitofmeasurement)); roundData(e.target.value) }} disabled={item.unitofmeasurement == "kg" ? true : false||item.unitofmeasurement == "" ? true : false} value={item.dcquantity_placed} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" onChange={(e) => { dispatch(changedeliverychallansqpbqtykg(e.target.value, item, item.unitofmeasurement)); roundData(e.target.value) }} disabled={item.unitofmeasurement == "box"||item.unitofmeasurement == "pcs" ? true : false || item.unitofmeasurement == "" ? true : false} value={Number(item.dcquantity_placed_kg)} size="sm" />
                                    </Form.Group>
                                </td>

                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changecostdeliverychallan(e.target.value, item, taxtype, item.unitofmeasurement)); roundData(e.target.value) }} value={item.dlp} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Select disabled={item.calunitofmeasurement == "box" ? true : item.productType !== "kgproduct" ? true : false} value={item.calunitofmeasurement} onChange={(e) => { dispatch(deliverychallancalmeasurement(e.target.value, item, item.calunitofmeasurement)); roundData(e.target.value) }} size="sm">
                                            <option>{item.calunitofmeasurement == "box" ? "PCS" : item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                            <option value="pcs">PCS</option>
                                            <option value="kg">KG</option>
                                        </Form.Select>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={item.tradediscount || ''} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.grossamount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" onChange={(e) => { dispatch(changedeliverychallandiscount(e.target.value, item, taxtype)); roundData(e.target.value) }} value={Number(item.schemeDiscount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.netAmount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.gstvalue)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.total)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type='button' className='btn btn-danger' onClick={() => { dispatch(removefromcartfromdeliverychallan(item.product.id)); roundData('') }} value="X" size="sm" />
                                    </Form.Group>
                                </td>
                                <td></td>
                            </tr>

                        )}
                    </tbody>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Packing&nbsp;&&nbsp;Forwarding</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={shippingcharge} onChange={(e) => settingship(e.target.value)} style={{ height: 30 }} />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Net&nbsp;Amount</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={grossAmount ? ((grossAmount).toFixed(2)) : "0"} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>
                            <td></td>

                        </tr>

                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>IGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={igstcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>

                        </tr>
                        <tr>

                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                            <td style={{ fontSize: 13 }}>SGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={SGSTcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>

                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>CGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={cgstcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Round&nbsp;off&nbsp;Value</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={round} onChange={(e) => settinground(e.target.value)} style={{ height: 30 }} />
                                </Form.Group>
                            </td>
                            <td></td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Grand&nbsp;Total</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={grandTotal ? ((grandTotal).toFixed(2)) : "0"} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>
                            <td></td>

                        </tr>
                    </tbody>
                </Table>
                <Button variant="primary" style={{width:'auto'}} onClick={submitdata} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
            </Form>
        </MDBCard>
    )
}