import React from 'react'
import { Mydatatable } from '../Product/Mydatatable';
import OpenCreate from './OpenCreate';

const Newopening = () => {
  let header = [
    { "label": "Id", "field": "id" },
    { "label": "Distributor", "field": "distname" },
    { "label": "Product Name", "field": "pname" },

    { "label": "Ean Code", "field": "peancode" },
    { "label": "Quantity", "field": "quantity" },
    { "label": "Created Date", "field": "createddate" },
    { "label": "Created Time", "field": "createdtime" },
    { "label": "Created By", "field": "createbyname" },
    { "label": "Updated Date", "field": "updateddate" },
    { "label": "Updated Time", "field": "updatedtime" },
    { "label": "Updated By", "field": "updatedbyname" },
//  { "label": "Remarks", "field": "remarks" },
    { "label": "Action", "field": "action","subaction1":<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg> }
  ]; 
      const sumofkeys=[{"first":'id'} ]
     
    //   "qty": 0,
    //   "productName": "Air Tite Bowl No.1",
    //   "eanCode": "8.90405766562"

  return (
 <>
 <OpenCreate/>

     <Mydatatable
    head={header}
    title={"Distributor Opening Stock"}
    axiostabledata={'distributor/openingstock'}
    Add={"Add OS"}
    Create={""}
    Edit={"/Openingedit"}
    View={"/Openingedit"}
     Sumofkeys={sumofkeys}
    />
</>


  )
}

export default Newopening