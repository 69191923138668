import { MDBCard, MDBCardBody } from 'mdb-react-ui-kit'
import { MDBDataTable } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import BrandService from '../../services/Brand'
import { FaUserEdit } from 'react-icons/fa'
import { AiOutlineDelete } from 'react-icons/ai'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import axios from 'axios'
import authHeader from '../../services/auth-header'
import Select from "react-select";
import { BASE_URL } from '../../services/Api'
import AuthService from '../../services/auth.service'

// import Select from 'react-select/dist/declarations/src/Select'
// import Select from 'react-select/dist/declarations/src/Select'

const Dmincreate = () => {
    const [show, setShow] = useState(false);
    const [error, setError] = useState(encodeURIComponent(' '))
    const [disword, setdisword] = useState(encodeURIComponent(' '))
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const[posts,setPosts]=useState([]);
    const[datacsv,setcsv]=useState([])
    const[plus,setplus]=useState([])
    const [product2, setproduct2] = useState([ ])
    const [distributor1,setdistributordata]=useState([])
    const [quantity, setquantity] = useState(0)
    const [user_date, setuser_date] = useState([])
    const [distributor, setdistributor] = useState([])
    const [product, setproduct] = useState([]);
    const [distributorStockItems,setdistributorStockItems] = useState([]);
    const[dist_roles,setdist_roles]=useState('');

    const currentuser = AuthService.getCurrentUser();

    let Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    
    const getItem = (id) =>{
  
  
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          BrandService.getDelete(id).then(res=>{
            setplus(1+plus)
            // window.location.reload();
          })
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        }
      })
    
    
    };
  
    // const onChangeproduct = ( e,pid ) => {
        
    //     let name = e;
    //     let value = pid;
    //      let dist_item = [];
    //         axios.get(BASE_URL + 'product/getById/'+value,{headers:authHeader()}).then(res=>{
    //             let distitem = {
    //                 "rate":res.data.dlp,
    //                 "amount":(res.data.dlp)*name,
    //                 "product":{"id":res.data.id},
    //                 'outwarddisid':distributor,
    //                 'outwardqty':name
    //             }
    //             dist_item.push(distitem)
                
    //         })
    //         setdistributorStockItems(dist_item);
    //     // "distributorStockItems": [
    //     //     {
    //     //         "rate": 5,
    //     //         "amount": 10,
    //     //         "product": {
    //     //             "id": 1
    //     //         }
    //     //     }
    //     // ]
        
    // };


  const handlesupersubmit=()=>{
    let dmsdate = user_date;
    const postData = {
        dmsdate,
      stockqty: Number(quantity),
      product: {
        id: product
      },
      distributorid: distributor,
    //   distributorStockItems
    };




    
    // const headers = authHeader();
        // console.log('mello',postData)
    axios.post('http://43.225.54.138:8080/scm/api/dms/', postData, { headers: authHeader() })
      .then(response => {
        window.location.reload()

        Toast.fire({
          icon: 'success',
          title: 'Successfully added'
        })
      })
      .catch(error => {
        console.error('Error occurred during POST request:', error);
      });
  
    }

    function getdistributorbyroles(){
      axios.get(BASE_URL + 'distributor/getById/' + currentuser.id,{headers:authHeader()}).then(res=>{
        setdist_roles(res.data.tradeName)
        setdistributor(res.data.id);
  
      })
    }
    useEffect(()=>{

      {currentuser.roles[0] == 'ROLE_DISTRIBUTOR' && getdistributorbyroles()}
      
        axios.get(BASE_URL+`product/page/0/10/asc/id/${error.length?error:encodeURIComponent(' ')}`, { headers: authHeader() })
        .then(response => {
        //   const endTime = new Date().getTime();  
        //   const elapsedTime = endTime - startTime;  
        //   console.log(`Request took ${elapsedTime} milliseconds`);
        if(response.data.data==undefined){
          setproduct2(response.data.Index.map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code  })));
        
        }
if(response.data.Index==undefined){
  setproduct2(response.data.data.map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code   })));

}


        console.log(response)  
    })
        .catch(error => {
          console.error(error);
        });

        // const [disword, setdisword] = useState(encodeURIComponent(' '))
        // const [dis,setdistributordata1]=useState([])
      
     
        
          axios.get(`http://43.225.54.138:8080/scm/api/distributor/page/0/10/asc/id/${disword.length?disword:encodeURIComponent(' ')}`, { headers: authHeader() })
          .then(response => {
        console.log(response)
        if(response.data.data==undefined){
          setdistributordata(response.data.Index.map(use => ({ value: use.id, label: use.trade_Name})));
       }  if(response.data.Index==undefined){
        setdistributordata(response.data.data.map(use => ({ value: use.id, label: use.trade_Name})));
      
       }
          
       })
      
      
      
      

    
    },[error,disword]);
  
   
    const data = () => {
      return {
        columns: [
  
           {
            label: 'NO',
            field: 'index',
            width: 100,
          },
  
          {
            label: 'Brand Name',
            field: 'name',
            width: 150,
            attributes: {
              'aria-controls': 'DataTable',
              'aria-label': 'username',
            },
            
          },               
          {
              label: 'Action',
              field: 'update',
              sort: 'disabled',
              width: 100,
            },
  
           
        ],
        rows: posts
      };
    };

const disid=(val)=>{
    console.log(val)
    setdistributor(val)
}

const productid=(val)=>{
  console.log(val)
  setproduct(val)
}








  return (
    <div>

 
  
     <div className='d-flex bd-highlight'>
     <div  className='p-2 w-100 bd-highlight'> <Link  > <button onClick={handleShow} class="btn btn-sm btn-primary">Add</button></Link></div>
     <div className='p-2 flex-shrink-1 bd-highlight'><CSVLink data={datacsv} class="btn btn-sm btn-primary">Export</CSVLink></div>  </div>
       


<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='row'>
    <div className='col'>
<label className='form-label'>Distributor</label>
{currentuser.roles[0] == 'ROLE_DISTRIBUTOR'?<input type='text' className='form-control' value={dist_roles} readOnly />:
 <Select options={distributor1} onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => disid(options.value)}/>}

    </div>
</div> 
        <div className='row'>
    <div className='col'>
<label className='form-label'>Product</label>
 {/* <select className='form-select' onChange={(e)=>setError(e.target.value)} >
    <option>Select Product</option>
 </select> */}
<Select options={product2} onInputChange={(inputValue) => setError(inputValue)} onChange={(options) => productid(options.value)}/>

 {/* <input type='text' className='form-control'  onChange={(e)=>setError(e.target.value)}  /> */}
     </div>
</div> 

<div className='row'>
    <div className='col'>
<label  className='form-label' >Date</label>
<input type='Date' className='form-control'  onChange={(e)=>setuser_date(e.target.value)}/>
    </div>
    <div className='col'>
<label  className='form-label'>Qty</label>
<input type='number' className='form-control' onChange={(e)=>{setquantity(e.target.value);}}/>
    </div>
</div>



        </Modal.Body>
        <Modal.Footer>
          <a className="btn btn-sm btn-secondary" variant="secondary" onClick={handleClose}>
            Close
          </a>
          <a className="btn btn-sm btn-secondary" variant="primary" onClick={()=>{handleClose();handlesupersubmit()}}>
            Save Changes
          </a>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Dmincreate