import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function Create() {
    const [staffAll,setstaffAll] = useState([]);
    const [assetAll,setassetAll] = useState([]);
    const [disword, setdisword] = useState(encodeURIComponent(' '))
    const [remarks,setremarks] = useState('');
    const [staffid,setstaffid] = useState('');
    const [asset,setasset] = useState('');
    const [formdate,setformdate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };

    const navigate = useNavigate();

let Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

        const getsupllierdata = () => {
        axios.get(BASE_URL + `staff/page/0/100/asc/id/${disword.length == 0?"%20":disword}`, {
            headers: authHeader()
        }).then(res => {
            if (res.data.data == undefined) {
                setstaffAll(res.data.Index.map(use => ({ value: use.id, label: use.staff_Name })));
            } if (res.data.Index == undefined) {
                setstaffAll(res.data.data.map(use => ({ value: use.id, label: use.staff_Name })));

            }
        })
    }

    const getAssetData = () => {
        axios.get(BASE_URL + `asset/page/0/10/asc/id/${disword.length == 0?"%20":disword}`,{
            headers:authHeader()
        }).then(res=>{
            if (res.data.data == undefined) {
                setassetAll(res.data.Index.map(use => ({ value: use.id, label: use.assetsname + '-' + use.mrp })));
            } if (res.data.Index == undefined) {
                setassetAll(res.data.data.map(use => ({ value: use.id, label: use.assetsname + '-' + use.mrp })));

            }
        })
    }
    useEffect(() => {
        getsupllierdata();
        getAssetData()
    }, [disword]);

    const onChangestaff = (e) =>{
         setstaffid(e.value)
    }
    const onChangeasset = (e) =>{
        setasset({"id":e.value})
    }
    const submitdata = () => {
        setIsLoading(true);
        axios.post(BASE_URL + 'assetassigntostaff/save',{remarks,staffid,asset,formdate},{
            headers:authHeader()
        }).then(res=>{
            navigate('/AssignToStaffTable/1');
            if(res.status==201||res.status==200){
                // alert("ee")
                Toast.fire({
                  icon: 'success',
                  title: 'Successfully added'
                })
              }
        }) .catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          })
          .finally(() => {
            setIsLoading(false); 
          }); 
    }
    return (
        <MDBCard className="company">
            <h1>Add Assign To Staff</h1>
            <Form className="product1">
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Staff Name</Form.Label>
                        <Select options={staffAll} onInputChange={(inputValue) => setdisword(inputValue)} onChange={(e) =>onChangestaff(e)}/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Asset Name</Form.Label>
                        <Select options={assetAll} onInputChange={(inputValue) => setdisword(inputValue)} onChange={(e) =>onChangeasset(e)}/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Date</Form.Label>
                        <Form.Control type="date" onChange={(e) => setformdate(e.target.value)}/>
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Remarks</Form.Label>
                        <Form.Control as="textarea" rows={3} name='remarks' onChange={(e)=>setremarks(e.target.value)} placeholder='Enter Remarks' autoComplete="off" />
                    </Form.Group>
                </Row>
            </Form>
            {/* <Button variant="primary" onClick={submitdata} type="button" >
                    Submit
                </Button> */}
                <Button variant="primary"   onClick={submitdata} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
        </MDBCard>
    )
}