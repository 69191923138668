import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../services/Api';
import authHeader from '../../services/auth-header';
import Select from "react-select";
import { useNavigate } from 'react-router-dom';

const Discreate = () => {
    const [distributor, setDistributor] = useState([]);
    const [disword, setDisword] = useState('');
    const [distributorsData, setDistributorsData] = useState([]);
    const [voucherNo, setVoucherNo] = useState('');
    const [amount, setAmount] = useState('');
    const [date, setDate] = useState('');
    const [file, setFile] = useState([]);
    const [image, setImage] = useState([]);
    const [imagePreview, setImagePreview] = useState('');
    const [loading, setLoading] = useState(false);  // Added loading state

    const onChangeCustomer = (e) => {
        setDistributor(e.value);
    };

    const navigate = useNavigate();

    useEffect(() => {
        axios.get(BASE_URL + `distributor/page/0/10/asc/id/${disword.length ? disword : encodeURIComponent(' ')}`, { headers: authHeader() })
            .then(response => {
                if (response.data.data) {
                    setDistributorsData(response.data.data.map(use => ({ value: use.id, label: use.trade_Name })));
                } else if (response.data.Index) {
                    setDistributorsData(response.data.Index.map(use => ({ value: use.id, label: use.trade_Name })));
                }
            });
    }, [disword]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        
        // Create a preview URL
        const previewUrl = URL.createObjectURL(file);
        setImagePreview(previewUrl);
    };

    const handleSubmit = () => {
        const id={'id':distributor};
        const emptyBlob = new Blob([""], { type: "application/octet-stream" });

        const formData = new FormData();
        formData.append('distributor', distributor);  
        formData.append('voucherdate', date);  
        formData.append('voucherno', voucherNo); 
        formData.append('amount', amount);  
        formData.append('pdf', file.length !== 0 ? file : emptyBlob);
        formData.append('img', image.length !== 0 ? image : emptyBlob);

        setLoading(true);  // Set loading to true before starting the request

        axios.post(BASE_URL + 'invoice/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                ...authHeader()  
            }
        })
        .then(response => {
            console.log(response.data);
            navigate('/Distable/1');  
        })
        .catch(error => {
            console.error('Error occurred:', error);
        })
        .finally(() => {
            setLoading(false);  // Set loading to false after request completes
        });
    };

    return (
        <div className='card'>
            <div className='card-body'>
                <div className='card-header card-title w-100'>
                    Untitled Module 
                </div>
                <div className='row'>
                    <div className='col'>
                        <label className='form-label'>Distributor</label>
                        <Select 
                            options={distributorsData} 
                            onInputChange={setDisword} 
                            onChange={onChangeCustomer} 
                        />
                    </div>
                    <div className='col'>
                        <label className='form-label'>Date</label>
                        <input 
                            type='date' 
                            className='form-control' 
                            value={date} 
                            onChange={(e) => setDate(e.target.value)} 
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <label className='form-label'>Voucher No</label>
                        <input 
                            type='text' 
                            className='form-control' 
                            value={voucherNo} 
                            onChange={(e) => setVoucherNo(e.target.value)} 
                        />
                    </div>
                    <div className='col'>
                        <label className='form-label'>Amount</label>
                        <input 
                            type='text' 
                            className='form-control' 
                            value={amount} 
                            onChange={(e) => setAmount(e.target.value)} 
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <label className='form-label'>Image</label>
                        <input 
                            type='file' 
                            className='form-control' 
                            onChange={handleImageChange} 
                        />
                    </div>
                    <div className='col'>
                        <label className='form-label'>Upload File</label>
                        <input 
                            type='file' 
                            className='form-control' 
                            onChange={(e) => setFile(e.target.files[0])} 
                        />
                    </div>
                </div>
                <div className='row'>
                    {imagePreview && (
                        <div className='col mt-3'>
                            <img src={imagePreview} alt="Uploaded" style={{ width: '5%', height: 'auto' }} />
                        </div>
                    )}
                </div>
                <br />
                <div className='row'>
                    <div className='col'>
                        <button 
                            className='btn btn-primary' 
                            onClick={handleSubmit}
                            disabled={loading}  // Disable the button while loading
                        >
                            {loading ? 'Submitting...' : 'Submit'}  {/* Display loading text or submit text */}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Discreate;
