import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Col, Row, Table, Form, Button, Modal } from 'react-bootstrap'
import ReatilerService from '../../services/Reatiler';
import ProductService from '../../services/ProductService';
import { BsFillEyeFill } from 'react-icons/bs';
import Accordion from 'react-bootstrap/Accordion';
import Swal from 'sweetalert2';
import Select from 'react-select';

import { useDispatch, useSelector } from 'react-redux';
import { addtocartworkorder, changecostworkorder, changediscount1workorder, changediscountworkorder, changeqtyworkorder, changetextworkorder, changeunitofMeasurementworkorder, emptydataworkorder, perworkorder, removefromcartworkorder } from '../../../redux/Action/workorder';
import WorkOrderService from '../../services/WorkOrderService';
import { Link, useNavigate } from 'react-router-dom';
import { MDBCard, MDBCardBody } from 'mdbreact';
import AuthService from '../../services/auth.service';
import authHeader from '../../services/auth-header';
import { BASE_URL } from '../../services/Api';

const Create = () => {
  let Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  const [Reatilerg, setret] = useState([]);
  const [retailerpin, setRetailerPin] = useState([]);
  const [pinCode, setPinCode] = useState('');
  const [workOrderDate, setworkOrderDate] = useState('');
  const [retailerid, setretailerid] = useState('');
  const [orderStatus, setorderStatus] = useState('true');
  const [deliveryAddress, setDeliveryAddress] = useState([]);
  const [ retailerAddress, setretailerAddress ] = useState('');
  const [remarks, setremarks] = useState('');
  const [product, setproduct] = useState([]);
  const [boxProductDiscount, setboxProductDiscount] = useState('');
  const [schemeboxProductDiscount, setschemeboxProductDiscount] = useState('');
  const [kgProductDiscount, setkgProductDiscount] = useState('');
  const [schemekgProductDiscount, setschemekgProductDiscount] = useState('');
  const [corporaetProductDiscount, setcorporaetProductDiscount] = useState('');
  const [schemecorporateProductDiscount, setschemecorporateProductDiscount] = useState('');
  const [cookerProductDiscount, setcookerProductDiscount] = useState('');
  const [schemecookerProductDiscount, setschemecookerProductDiscount] = useState('');
  const [noshProductDiscount, setnoshProductDiscount] = useState('');
  const [schemenoshProductDisocunt, setschemenoshProductDisocunt] = useState('');
  const [totalPcsQuantity, setTotalPcsQuantity] = useState(0);
  const [totalnet, settotalnet] = useState();
  const [grossfull, setgrossfull] = useState();
  const [gstvalue, setgstvalue] = useState();
  const [amount, setamount] = useState();
  const [kg, setkg] = useState();
  const [sumbox, setsumbox] = useState(0);
  const [sumofcorp, setcorp] = useState(0);
  const [sumofcorpcs, setcorpc] = useState(0);
  const [boxamt, setboxsmt] = useState(0);
  const [amt, setamt] = useState(0);
  const [kg1, setkg1] = useState(0);
  const [kg2, setkg2] = useState(0);
  const [kg3, setkg3] = useState(0);
  const[imagedisplay,setdiplay]=useState([])

  const [c1, setc1] = useState(0);
  const [c2, setc2] = useState(0);
  const [c3, setc3] = useState(0);

  const [n1, setn1] = useState(0);
  const [n2, setn2] = useState(0);
  const [n3, setn3] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = 'font-size 2s';
    element.style.fontSize = '30px';
    element.style.fontWeight = '600';
    // font-weight: 600;
    setTimeout(() => {
       
      document.getElementById("typer1").classList.add('promote');
      element.style.fontSize = '24px';
    }, 1000);
  };

  const[currentUser,setCurrentUser]=useState("");
  /* Navigator */
  const navigate = useNavigate();

  /* Redux */
  const dispatch = useDispatch();
  const workorderItem = useSelector((state) => state.workorderdata);
  // let grossAmount = workorderItem.length && workorderItem.map(item => Math.round(Number(item.total))).reduce((prev, next) => Math.round(prev - (-next)));

  /* Current Date */
  const datevalue = new Date();
  const defaultValue = datevalue.toLocaleDateString('en-CA');

  /* Product Data */
  const productdatavalue = () => {

    axios.get( BASE_URL + 'product/page/0/100/asc/id/%20',{
      headers:authHeader()
  }).then(res=>{

    setproduct((res.data.Index).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
  })
  
  };


     const customFilter = (e) => {
      let name = e;
      axios.get( BASE_URL + 'product/page/0/100/asc/id/'+name,{
          headers:authHeader()
      }).then(res=>{
        setproduct((res.data.data).map(use=>({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })))
      })
     }
  
  /* Reatiler Data */
  // const retailerdatavalue = () => {

  //   ReatilerService.getReatilerTable().then((res) => {
  //     let aa = res.data;
  //     setret(aa.map(user => ({ value: user.id, label: user.tradeName })));
  //     setRetailerPin(res.data);

  //   });
  // };
const[ret1,setsetret1]=useState('');
const loginretailer = () => {
  const user = AuthService.getCurrentUser();
  // console.log("mello",user.roles)
  setCurrentUser(user.roles);
  if((user.roles) == 'ROLE_RETAILER'){
  ReatilerService.getRetailerGet(user.id).then(res=>{
    setboxProductDiscount(res.data.boxProductDiscount);
    setschemeboxProductDiscount(res.data.schemeboxProductDiscount);
    setkgProductDiscount(res.data.kgProductDiscount);
    setschemekgProductDiscount(res.data.schemekgProductDiscount);
    setcorporaetProductDiscount(res.data.corporaetProductDiscount);
    setschemecorporateProductDiscount(res.data.schemecorporateProductDiscount);
    setcookerProductDiscount(res.data.cookerProductDiscount);
    setschemecookerProductDiscount(res.data.schemecookerProductDiscount);
    setnoshProductDiscount(res.data.noshProductDiscount);
    setschemenoshProductDisocunt(res.data.schemenoshProductDisocunt);
    setsetret1(res.data.tradeName);
    setretailerid(res.data.id);
    setPinCode(res.data.pinCode);
    if((res.data).deliveryAddress.length == 0){
      setDeliveryAddress([]);
    } else{
    setDeliveryAddress((res.data).deliveryAddress.map(use=>({value:use.id,label:use.delivery_address})));
    }

  });
}
}
  useEffect(() => {
    loginretailer();
    // retailerdatavalue();
    productdatavalue();
    setworkOrderDate(defaultValue);
    dispatch(emptydataworkorder());
  }, []);

  /* Reatiler Pincode Set */
  function pinCodedata(e) {
    const name = e.value;
    setretailerid(name)
    axios.get(BASE_URL + 'retailer/getById/'+name,{
      headers:authHeader()
    }).then(res=>{
      
      let user = res.data;
      setPinCode(user.pinCode)
      setboxProductDiscount(user.boxProductDiscount);
      setkgProductDiscount(user.kgProductDiscount);
      setcorporaetProductDiscount(user.corporaetProductDiscount);
      setcookerProductDiscount(user.cookerProductDiscount);
      setnoshProductDiscount(user.noshProductDiscount);
      setschemeboxProductDiscount(user.schemeboxProductDiscount);
      setschemekgProductDiscount(user.schemekgProductDiscount);
      setschemecorporateProductDiscount(user.schemecorporateProductDiscount);
      setschemecookerProductDiscount(user.schemecookerProductDiscount);
      setschemenoshProductDisocunt(user.schemenoshProductDisocunt);
      if(user.deliveryAddress.length == 0){
        setDeliveryAddress([]);
      } else{
      setDeliveryAddress(user.deliveryAddress.map(use=>({value:use.id,label: use.delivery_address })));
      }
    })
  }

    // Retailer Address OnChange
    let getRetailerAddressChange = (e) => {
      setretailerAddress(e.value)
    }
  /* Redux send data */
  function deleteItem(e) {
    const filteredItems = workorderItem.filter(item => item.id === e);

    if (filteredItems.length == "0") {
      dispatch(addtocartworkorder(e, ({ "schemeboxProductDiscount": schemeboxProductDiscount, "schemekgProductDiscount": schemekgProductDiscount, "schemecorporateProductDiscount": schemecorporateProductDiscount, "schemecookerProductDiscount": schemecookerProductDiscount, "schemenoshProductDisocunt": schemenoshProductDisocunt, "kgProductDiscount": kgProductDiscount, "boxProductDiscount": boxProductDiscount, "corporaetProductDiscount": corporaetProductDiscount, "cookerProductDiscount": cookerProductDiscount, "noshProductDiscount": noshProductDiscount })))
    }
  }

  /* Product View */
  const [show, setShow] = useState(false);
  const [productdata, setProductdata] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [disword, setdisword] = useState(encodeURIComponent(' '))
  const [dis,setdistributordata]=useState([])

  const productdetails = (e) => {
    ProductService.getProductEdit(e).then(res => {
      setProductdata(res.data);
      setdiplay(res.data.productImages);
  //  const updatedImages = res.data.productImages.slice(-4);

  //  setdiplay(updatedImages);
    })
  }
  
  useEffect(() => {
  
    axios.get(`http://43.225.54.138:8080/scm/api/retailer/page/0/10/asc/id/${disword.length?disword:encodeURIComponent(' ')}`, { headers: authHeader() })
    .then(response => {
  // console.log(response.data,"mello")
  if(response.data.data==undefined){
    setdistributordata(response.data.Index.map(use => ({ value: use.id, label: use.retailername})));
 }  if(response.data.Index==undefined){
  setdistributordata(response.data.data.map(use => ({ value: use.id, label: use.retailername})));

 }
    
 })



}, [ disword])



  const submitform = () => {
setIsLoading(true); 
    let retailerPinCode = pinCode;
    let workOderItems = workorderItem;
    let retailer = { "id": retailerid }
    let kgProductTotalQtyKg = kg1;
    let kgProductTotalQtyPcs = kg2; 
    let kgProductTotalprice = kg3;
    let boxProductTotalQtyPcs = sumbox ;
    let boxProductTotalprice = boxamt;
    let corporateProductTotalQtyPcs = sumofcorp ;
    let corporateProductTotalprice = amt;
    let cookerProductTotalQtyPcs = c1 ;
    let cookerProductTotalprice = c3;
    let noshProductTotalQtyPcs = n1;
    let noshProductTotalprice = n2;
    let totalQtyKg = kg;
    let totalQtyPcs = totalPcsQuantity;
    let grossTotal = grossfull;
    let netValue = totalnet;
    let taxAmount =  gstvalue;
let deliveryAddress=retailerAddress;
    
    const workorder = {
      workOrderDate,
      retailer,
      deliveryAddress,
      retailerPinCode,
      orderStatus,
      remarks,
      workOderItems,
      kgProductTotalQtyKg,
      kgProductTotalQtyPcs,
      kgProductTotalprice,
      boxProductTotalQtyPcs,
     boxProductTotalprice ,
     corporateProductTotalQtyPcs ,
     corporateProductTotalprice ,
     cookerProductTotalQtyPcs ,
     cookerProductTotalprice ,
     noshProductTotalQtyPcs ,
     noshProductTotalprice,
     totalQtyKg,
     totalQtyPcs,
     grossTotal,
     netValue,
     taxAmount,
     retailerAddress
    }
    // console.log("mello",workorder)
    if (workOderItems == "") {

    } else {
      WorkOrderService.getWorkOrderCreate(workorder).then(res => {
        if (res.status == 201 || res.status == 200) {
          // alert("ee")
          Toast.fire({
            icon: 'success',
            title: 'Successfully added'
          })
        }
        navigate('/Newoo/1')

      }).catch(error => {
        console.error('Error adding  :', error);
        if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `
            <div>
              <p id="issueText">Why do I have this issue?</p>
              <ul>
          
              <li id="typer1">You might have an internet issue</li>
              </ul>
            </div>
          `,
          didOpen: () => {
            applyFontSizeTransition('issueText');
          }
        });
      }
      }).finally(() => {
        setIsLoading(false); 
      });

      dispatch(emptydataworkorder());
    }

  }
  const calculateSum = () => {
    let workOderItems = workorderItem.map((order) => {
      const measurement = parseFloat(order.measurement);
      return isNaN(measurement) || !isFinite(measurement) ? 0 : measurement;
    }).reduce((prev, curr) => prev + curr, 0);



    workOderItems = parseFloat(workOderItems.toFixed(2));
    setTotalPcsQuantity(workOderItems);


    ///////////////////////////////////////////////////
    let totalnet = workorderItem.map((order) => parseFloat(order.total))
      .reduce((prev, curr) => prev + curr, 0);
    totalnet = parseFloat(totalnet.toFixed(2));
    settotalnet(totalnet)
    ////////////////////////////////////////////////////////////////
    let grossnet = workorderItem.map((order) => parseFloat(order.grossamount))
      .reduce((prev, curr) => prev + curr, 0);

    grossnet = parseFloat(grossnet.toFixed(2));
    setgrossfull(grossnet)
    ////////////////////////////////////////

    let gst = workorderItem.map((order) => parseFloat(order.gstvalue))
      .reduce((prev, curr) => prev + curr, 0);
    let gt = parseFloat(gst.toFixed(2))
    setgstvalue(gt)
    ////////////////////////////////////////////////////////////////
    let amt = workorderItem.map((order) => parseFloat(order.amount))
      .reduce((prev, curr) => prev + curr, 0);
    const tt = parseFloat(amt.toFixed(2))
    setamount(tt)

    let kg1 = workorderItem
      .map((order) => {
        const parsedQty = parseFloat(order.qty);
        return isNaN(parsedQty) || !isFinite(parsedQty) ? 0 : parsedQty;
      })
      .reduce((prev, curr) => prev + curr, 0);

    const tt1 = parseFloat(kg1.toFixed(2));
    setkg(tt1);
    /////////////////////////////////////////////////
    let amt12 = workorderItem
      .map((order) => parseFloat(order.amount))
      .reduce((prev, curr) => prev + curr, 0);


    const kgCorporateProducts = workorderItem.filter((order) => order.productType === "boxproduct")
    setsumbox(kgCorporateProducts.map((order) => parseFloat(order.measurement))
      .reduce((prev, curr) => prev + curr, 0))



    setboxsmt(workorderItem.filter((order) => order.productType === "boxproduct").map((order) => parseFloat(order.amount))
      .reduce((prev, curr) => prev + curr, 0))




    const sumkgCor = workorderItem.filter((order) => order.productType === "corporateproduct")
    setcorp(sumkgCor.map((order) => parseFloat(order.measurement))
      .reduce((prev, curr) => prev + curr, 0))


    const sumpccor = workorderItem.filter((order) => order.productType === "corporateproduct")
    setcorpc(sumpccor.map((order) => parseFloat(order.qty))
      .reduce((prev, curr) => prev + curr, 0),)
    setamt(sumpccor.map((order) => parseFloat(order.amount))
      .reduce((prev, curr) => prev + curr, 0),)


    const kgproductpc = workorderItem.filter((order) => order.productType === "kgproduct")
    const time = kgproductpc.map((order) => parseFloat(order.qty))
      .reduce((prev, curr) => prev + curr, 0)
    const time2 = kgproductpc.map((order) => parseFloat(order.measurement))
      .reduce((prev, curr) => prev + curr, 0)
    const totalkgg = kgproductpc.map((order) => parseFloat(order.amount))
      .reduce((prev, curr) => prev + curr, 0)
    setkg1(parseFloat(time).toFixed(2))
    setkg2(time2)
    setkg3(totalkgg)

    const cooker = workorderItem.filter((order) => order.productType === "cookerproduct")
    const pcs = cooker.map((order) => parseFloat(order.measurement))
      .reduce((prev, curr) => prev + curr, 0)
    setc1(pcs)
    const c3total = cooker.map((order) => parseFloat(order.amount))
      .reduce((prev, curr) => prev + curr, 0)
    setc3(c3total)


    const noshing = workorderItem.filter((order) => order.productType === "noshproduct")
    const nospcs = noshing.map((order) => {
      const measurement = parseFloat(order.measurement);
      return isNaN(measurement) || !isFinite(measurement) ? 0 : measurement;
    }).reduce((prev, curr) => prev + curr, 0);
    setn1(nospcs)
    const nospcs1 = noshing.map((order) => {
      const measurement1 = parseFloat(order.qty);
      return isNaN(measurement1) || !isFinite(measurement1) ? 0 : measurement1;
    }).reduce((prev, curr) => prev + curr, 0);
    // setn3(nospcs1)
    setn3(parseFloat(nospcs1).toFixed(2))

    const nostotal = noshing.map((order) => parseFloat(order.amount))
      .reduce((prev, curr) => prev + curr, 0)
    setn2(nostotal)








  }


  return (

    <MDBCard  >
      <div className='d-flex bd-highlight'>
        <div className="card-header card-title w-100">Add Secondary Order </div>
        <Link className='p-2 w-200 card-header card-title bd-highlight' to={'/Newoo/1'}><Button>Back</Button></Link>
        
      </div>
      <MDBCardBody>


        <div>
          <Row md={3}>
            <Col>
              <label class="form-label">  Order  Date: </label>
              <input type="date" class="form-control" value={workOrderDate} onChange={(e) => setworkOrderDate(e.target.value)} />
            </Col>
            {currentUser == 'ROLE_RETAILER'?( <Col>
              <label class="form-label">Retailer Name: </label>
            <Form.Control type="text" value={ret1} readOnly />
          
            </Col>):( <Col>
              <label class="form-label">Retailer Name: </label>
              {/* <Select options={Reatilerg} onChange={(e) => { pinCodedata(e) }} /> */}

              <Select options={dis}  onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => pinCodedata(options)}/>

              
            </Col>)}
            {currentUser == 'ROLE_RETAILER'?( <Col>
              <label class="form-label">Retailer Address: </label>
              <Select options={deliveryAddress} onChange={(e) => { getRetailerAddressChange(e) }} />
          
            </Col>):( <Col>
              <label class="form-label">Retailer Address: </label>
              <Select options={deliveryAddress} onChange={(e) => { getRetailerAddressChange(e) }} />
            </Col>)}
            <Col>
              <label class="form-label">Retailer Pin Code: </label>
              <input type="number" value={pinCode} class="form-control" readOnly />
            </Col>
          </Row>
          <Row md={3}>
            {/* <Col>
                    <label className='form-label'>Order-Status</label>
                    <select class="form-select" value={orderStatus} onChange={(e) => setorderStatus(e.target.value)}>
                      <option >Choose the status</option>
                      <option value="true">Active</option>
                      <option value="false">InActive</option>
                    </select>
                  </Col> */}
            {/* <Col>
                    <label className='form-label'>Sales id</label>
                    <input type="number" value={1} className='form-control' />
                  </Col> */}

            {/* <Col>
                    <label className='form-label'>Created by</label>
                    <input type="text" className='form-control' value={"Admin"} />
                  </Col> */}
          </Row>

          <Row md={10}>
            <Col>
              <label className='form-label'>Remarks</label>
              <textarea className='form-control' onChange={(e) => setremarks(e.target.value)} />
            </Col>
          </Row>
        </div>


        <br></br>
        <label class="form-label"><b>Add&nbsp;Product</b> </label>
        <Select options={product} onChange={(options) => deleteItem(options.value)} onInputChange={(e) => customFilter(e) }/>
        <br></br>
        <Table striped responsive hover size="sm">
          <thead className='p-3 mb-2 bg-success text-white'>
            <tr>
              <th style={{ width: '20%' }}>PRODUCT&nbsp;NAME</th>
              <th style={{ width: '9%' }}>UOM</th>
              <th style={{ width: '8%' }}>QTY(PCS)</th>
              <th style={{ width: '8%' }}>QTY(KG)</th>
              <th style={{ width: '8%' }}>RATE (₹)</th>
              <th style={{ width: '8%' }}>GROSS AMOUNT(₹)</th>
              <th style={{ width: '8%' }}>TRADE DISCOUNT %</th>
              <th style={{ width: '8%' }}>SCHEME DISCOUNT %</th>
              <th style={{ width: '8%' }}>NET AMOUNT (₹)</th>
              {/* <th>Per&nbsp;Unit&nbsp;Rate</th> */}
              <th style={{ width: '8%' }}>GST VALUE (₹)</th>
              <th style={{ width: '8%' }}>TOTAL (₹)</th>
              <th>View</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody >
            {workorderItem.map((item) =>
              <tr key={item.id} >
                <td> <Form.Group as={Col} md="12" >
                  <Form.Control type="text" value={item.productName} size="sm" />
                </Form.Group>
                  <Form.Control as="textarea" rows={2} onChange={(e) => dispatch(changetextworkorder(e.target.value, item))} value={item.text} className='form-control' />
                </td>
                <td><Form.Group as={Col} md="12">
                  <Form.Select disabled={item.productType !== "kgproduct" ? true : false} onChange={(e) => { dispatch(changeunitofMeasurementworkorder(e.target.value, item, item.unitofmeasurement)); }} size="sm" >
                    <option>{item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                    <option value="pcs">PCS</option>
                    <option value="kg">KG</option>
                  </Form.Select>
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control type="number" min="0" onChange={(e) => { dispatch(perworkorder(e.target.value, item, item.unitofmeasurement)); calculateSum(); }} disabled={item.unitofmeasurement == "kg" ? true : false || item.unitofmeasurement==""?true:false} value={item.measurement} size="sm" />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control type="number" min="0" onChange={(e) => { dispatch(changeqtyworkorder(e.target.value, item, item.unitofmeasurement)); calculateSum(); }} disabled={item.unitofmeasurement == "pcs" || item.unitofmeasurement == "box" ? true : false || item.unitofmeasurement==""?true:false} value={item.unitofmeasurement == "box" ? "" : item.qty} size="sm" />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control type="number" min="0" disabled={item.unitofmeasurement==""?true:false} onChange={(e) => { dispatch(changecostworkorder(e.target.value, item, item.unitofmeasurement)); calculateSum(); }} value={item.dlp} size="sm" />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control type="number" min="0" disabled={item.unitofmeasurement==""?true:false} value={Number(item.grossamount)} size="sm" readOnly requied />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control type="number" min="0" disabled={item.unitofmeasurement==""?true:false} value={item.discount} size="sm" />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control type="number" min="0" disabled={item.unitofmeasurement==""?true:false} onChange={(e) => { dispatch(changediscount1workorder(e.target.value, item, item.unitofmeasurement)); calculateSum(); }} value={Number(item.discount1)} size="sm" requied />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control type="number" min="0" disabled={item.unitofmeasurement==""?true:false} value={Number(item.total)} size="sm" readOnly requied />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control type="number" min="0" disabled={item.unitofmeasurement==""?true:false} value={Number(item.gstvalue)} size="sm" readOnly requied />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control type="number" min="0" disabled={item.unitofmeasurement==""?true:false} value={Number(item.amount)} size="sm" readOnly requied />
                </Form.Group></td>
                <td>
                  <div onClick={(e) => { handleShow(e); productdetails(item.id) }} style={{ textAlign: 'center', border: '1px solid grey', marginTop: '15px', cursor: 'pointer',padding:'4px 8px' }} > <BsFillEyeFill style={{color:'var(--bs-link-color)'}}/></div>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    size='lg'
                  >
                    <Modal.Header closeButton style={{ background: 'white', color: 'white' }}>
                      <Modal.Title style={{color:"#164194"}}>Product Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {imagedisplay.map((user,index) => (
                <img src={"http://43.225.54.138:8080/scm/Images/" + user.name} className="images" style={{ width: '200px', height: '150px' }} />
              ))}
            
                      <Row className="mb-1">
                        <Form.Group as={Col} md="12" controlId="formBasicName">
                          <span style={{ fontSize: '18px', fontWeight: 600 }}>Product Name : - {productdata.productName}</span>

                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Short Name : - {productdata.shortName}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Ean Code No : - {productdata.eanCode}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Standard Qty Per Box : - {productdata.standardQtyPerBox}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Groups : - {productdata.productGroup}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Category : - {productdata.category}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>UOM Primary : - {productdata.uomPrimary}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>UOM Secondary : - {productdata.uomSecondary}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>DLP(₹) : - {productdata.dlp}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>MRP(₹) : - {productdata.mrp}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Capacity(ml) : - {productdata.capacity}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Diameter : - {productdata.diameter}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>HSN Code : - {productdata.hsnCode}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Brand Name : - {productdata.brand ? "Neelam" : ''}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Product Type : - {productdata.productType}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>IGST% : - {productdata.igst}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>CGST% : - {productdata.cgst}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>SGST% : - {productdata.sgst}</span>
                        </Form.Group>
                      </Row>
                    </Modal.Body>
                  </Modal>
                </td>
                <td>
                  <Form.Group as={Col} md="12">
                    <Form.Control type='button' className='btn btn-danger' value="X" onClick={() => dispatch(removefromcartworkorder(item.id))} size="sm" />
                  </Form.Group>
                </td>
                {/* <button className="form-control btn-sm" id='c17' onClick={() => dispatch(removefromcart(item.id))}>x</button> */}
              </tr>
            )}
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                {/* <td> <span className="cname">Gross&nbsp;amount&nbsp;<b>₹</b></span></td>
                <td>
                  <Form.Group as={Col} md="12">
                    <Form.Control type="text" value={grossAmount} size="sm" />
                  </Form.Group>
                </td> */}
                <td></td>
              </tr>
            {workorderItem.length !== 0 &&
              (
                <tr>
                  <td className="bold">Total</td>
                  <td></td>
                  <th className="bold">{totalPcsQuantity}</th>
                  <th className="bold">{kg}</th>
                  {/* <td></td>
                <td></td> */}
                  <td></td>
                  <th className="bold"> {grossfull}</th>
                  <th className="bold"></th>
                  <td></td>
                  <th className="bold">{totalnet}</th>

                  <th className="bold">{gstvalue}</th>
                  <th className="bold">{amount}</th>
                  <th></th>
                  <th></th>
                  {/* <td>Total Amount</td> */}
                </tr>
              )}
          </tbody>
        </Table>
        <table className='table'>

          <tr>

            {/* {workorderItem.length !== 0 && (
              <>
                <td className='qtyn nn'><b>{totalPcsQuantity}</b></td>
                <td className='kgg'><b>{kg}</b></td>
                <td className='gross'><b>{grossfull}</b></td>
                <td className='netn'><b>{gstvalue}</b></td>
                <td className='gstn'><b>{totalnet}</b></td>
                <td className='total'><b>{amount}</b></td>
              </>
            )} */}
          </tr>

        </table>

        <table>
          <tr>
            {workorderItem.length !== 0 && (
              <>
                <tr>
                  <th className='namee'>Summary</th>
                  <th className='namee'> Total Qty(KG)</th>
                  <th className='namee'>Total Qty(Pcs)</th>
                  <th className='namee'>Total price</th>
                </tr>
                <tr>
                  <th>Box Product</th>
                  <th></th>
                  <th>{sumbox}</th>
                  <th>{boxamt}</th>
                </tr>
                <tr>
                  <th>KG Product</th>
                  <th>{kg1}</th>
                  <th>{kg2}</th>
                  <th>{kg3}</th>
                </tr>
                <tr>
                  <th>Corporate Product</th>
                  <th></th>
                  <th>{sumofcorp}</th>
                  <th>{amt}</th>
                </tr>
                <tr>
                  <th>Cooker Product</th>
                  <th></th>
                  <th>{c1}</th>
                  <th>{c3}</th>
                </tr>
                <tr>
                  <th>Nosh Product</th>
                  <th> </th>
                  <th>{n1}</th>
                  <th>{n2}</th>
                </tr>
                <tr>
                  <th>Total</th>
                  <th>{kg}</th>
                  <th>{totalPcsQuantity}</th>
                  <th>{amount}</th>
                </tr>
              </>
            )}
          </tr>



        </table>



        <br></br>
        <Button variant="primary" style={{width:'auto'}} onClick={submitform} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
      </MDBCardBody>
    </MDBCard>

  )
}

export default Create