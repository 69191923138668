import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import Swal from "sweetalert2";


export default function Create() {
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
        const element = document.getElementById(elementId);
        element.style.transition = 'font-size 2s';
        element.style.fontSize = '30px';
        element.style.fontWeight = '600';
        // font-weight: 600;
        setTimeout(() => {

            document.getElementById("typer1").classList.add('promote');
            element.style.fontSize = '24px';
        }, 1000);
    };
    const [addresses, setAddresses] = useState([]); 
    const handleChange = (index, event) => {
        const newAddresses = [...addresses];
        newAddresses[index][event.target.name] = event.target.value;
        setAddresses(newAddresses);
    };
    const addAddress = () => {
        console.log(addAddress)
        setAddresses([...addresses, { supplier_delivery_address: '', state: "", pincode: "" }]);
    };

    const removeAddress = (index) => {
        const newAddresses = [...addresses];
        newAddresses.splice(index, 1);
        setAddresses(newAddresses);
    };

    const [companyname, setcompanyname] = useState('');
    const [suppliername, setsuppliername] = useState('');
    const [phonenumber, setphonenumber] = useState('');
    const [gstno, setgstno] = useState('');
    const [email, setemail] = useState('');
    const [address, setaddress] = useState('');
    const [cities, setcities] = useState('');
    const [zipcode, setzipcode] = useState('');
    const [accountno, setaccountno] = useState('');
    const [bankname, setbankname] = useState('');
    const [branch, setbranch] = useState('');
    const [ifsc, setifsc] = useState('');
    const [openingbal, setopeningbal] = useState('');
    const [openingbaltype, setopeningbaltype] = useState('debit');
    const [openingbaldate, setopeningbaldate] = useState('2023-04-01');
    const [countryoptions, setCountryoptions] = useState({ value: "101", label: "India" });
    const [statedata, setstatedata] = useState([]);
    const [statevalue, setstatevalue] = useState([]);
    const [states, setStates] = useState('');
    const [password, setPassword] = useState('');
    const [state_code, setstate_code] = useState('');
    const [colourtype, setcolourtype] = useState('');
    const [status, setstatus] = useState('');
    const [sendotp, setsendotp] = useState('');
    const [verifydone, setverifydone] = useState('');
    const [opttimeout, setopttimeout] = useState('');
    const [emailLoginStatus, setemailLoginStatus] = useState('Disable');

    const navigate = useNavigate();
    /* Get All Zones */
    const getAllState = () => {

        axios.get(BASE_URL + 'state', {
            headers: authHeader()
        }).then(res => {
            setstatedata((res.data).map(user => ({ value: user.id, label: user.name })));
            setstatevalue(res.data);
        })
    };

    useEffect(() => {
        getAllState();
    }, []);


    /* State Change */
    const onSelect = (e) => {
        setStates({ "id": e.value });

        statevalue.map(user => {
            if (user.id == e.value) {
                setstate_code(user.state_code);
            }
        })

    };

    const [tradeName, settradeName] = useState('');
    const [gstNumber, setgstNumber] = useState('');
    // const [addresses, setAddresses] = useState([]); // Array of addresses

    const getgstdetails = () => {
        // alert(typeof gstno)
        if(checksum(gstno)){
        let cap
        axios.get(`http://43.225.54.138:8080/scm/gst/gstin/${gstno}`, { headers: authHeader() })
            .then(response => {
                console.log(response.data)
                // setGstdetails(response.data)
            
 const gstTypeChar = gstno[5].toUpperCase();
 const addrBno = response.data.AddrBno || '';  
 const addrFlno = response.data.AddrFlno || ''; 
 const addrLoc = response.data.AddrLoc || '';  
 const addrSt = response.data.AddrSt || '';  
 const addrPncd = response.data.AddrPncd || '';  
 
  const fullAddress = `${addrBno}, ${addrFlno}, ${addrLoc}, ${addrSt}, ${addrPncd}`;
 
  setaddress(fullAddress);
  let businessType;
  switch (gstTypeChar) {
    case 'P':
      businessType = 'Proprietor';
      setcompanyname(response.data.TradeName)
      setsuppliername(response.data.LegalName)
        break;
    case 'F':
      businessType = 'Firm';
      setcompanyname(response.data.TradeName)
      setsuppliername(response.data.LegalName)
      console.log(response.data.AddrBno,response.data.AddrFlno,response.data.AddrLoc,response.data.AddrSt,response.data.AddrPncd)
             break;
    case 'C':
      businessType = 'Company';
      setcompanyname(response.data.TradeName)
      setsuppliername(response.data.LegalName)
      console.log(response.data.AddrBno,response.data.AddrFlno,response.data.AddrLoc,response.data.AddrSt,response.data.AddrPncd)
       break;
    default:
      businessType = 'Unknown type';
  }
                axios.get(`http://43.225.54.138:8080/scm/api/state
            `, { headers: authHeader() })
                    .then(response1 => {
                        // setgststated(response1.data)
                        //   setsname(response.data.name)
                        let statename = response1.data.filter((item) => item.state_code == response.data.StateCode).map((item) => item.name).toString()
                        console.log(response1.data)
                        console.log(addresses.length)
                        if (addresses.length !== 0) {
                            const updatedAddresses = addresses.map((item, index) => {
                                if (item.id === response.data.Gstin) {
                                    return item;
                                } else {
                                    const newValue = {
                                        supplier_delivery_address: response.data.AddrBnm + " " + response.data.AddrBno + " " + response.data.AddrFlno + " " + response.data.AddrLoc + " " + response.data.AddrSt,
                                        state: statename,
                                        pincode: response.data.AddrPncd,
                                        id: response.data.Gstin
                                    };
                                    return newValue;
                                }
                            });


                            const indexOfItemToDelete = updatedAddresses.findIndex(item => item.id === response.data.Gstin);
                            if (indexOfItemToDelete !== -1) {
                                updatedAddresses.splice(indexOfItemToDelete, 1);
                            }

                            const newItem = {
                                supplier_delivery_address: response.data.AddrBnm + " " + response.data.AddrBno + " " + response.data.AddrFlno + " " + response.data.AddrLoc + "" + response.data.AddrSt,
                                state: statename,
                                pincode: response.data.AddrPncd, id: response.data.Gstin,
                                id: response.data.Gstin
                            };
                            updatedAddresses.push(newItem);

                            setAddresses(updatedAddresses);
                        }


                        else {
                            setAddresses([...addresses, { supplier_delivery_address: response.data.AddrBnm + " " + response.data.AddrBno + " " + response.data.AddrFlno + " " + response.data.AddrLoc + "" + response.data.AddrSt, state: statename, pincode: response.data.AddrPncd, id: response.data.Gstin }])

                        }

                        console.log(cap);

                    })
                    .catch(error => {
                        console.error(error);
                    });
            })
            .catch(error => {
                console.error(error);
            });
        }else{
            alert("Entered Gst is Incorrect")
        }

    }

    const sendemail = (e) => {
        axios.post(BASE_URL + 'auth/sendverificationcode/otp', { "email": e }, {
            headers: authHeader()
        }).then(res => {
            setstatus(res.data)
            setverifydone('');
            setopttimeout('');

        })
    }

    const verifyemail = (e, f) => {
        let dataverify = { "email": e, "otp": f };

        axios.post(BASE_URL + 'auth/verifyEmailWithOtp', dataverify, {
            headers: authHeader()
        }).then(res => {
            // console.log('mello',res.data)
            if (res.data == 'invalid email or otp') {
                alert('Invalid OTP! Please Enter Valid OTP')
            } else if (res.data == 'time limit exceed !!! please generate new otp .') {
                alert('Your OTP has been Expired! , Please Generate New OTP')
                setopttimeout(res.data)

            } else {
                setverifydone(res.data)
            }
        })
    }

    const submitdata = () => {
        setIsLoading(true);
        let country = { "id": countryoptions.value };
        let statecode = state_code;
        let supplierdata = {
            companyname, suppliername, phonenumber, gstno, email, address, zipcode,
            accountno, bankname, branch, ifsc, openingbal, supplierAddresses: addresses, openingbaltype, openingbaldate, country, states, cities, password, statecode, colourtype, emailLoginStatus
        }
        if (emailLoginStatus !== "") {
            axios.post(BASE_URL + 'supplier/add', supplierdata, {
                headers: authHeader()
            }).then(res => {
                navigate('/SupplierTable/1')
            }).catch(error => {
                console.error('Error adding  :', error);
                if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
                        didOpen: () => {
                            applyFontSizeTransition('issueText');
                        }
                    });
                }
            })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }

    function checksum(g){
        let p;
        let regTest = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(g)
         if(regTest){
            let a=65,b=55,c=36;
            return Array['from'](g).reduce((i,j,k,g)=>{ 
               p=(p=(j.charCodeAt(0)<a?parseInt(j):j.charCodeAt(0)-b)*(k%2+1))>c?1+(p-c):p;
               return k<14?i+p:j==((c=(c-(i%c)))<10?c:String.fromCharCode(c+b));
            },0); 
        }
        return regTest
    }

    // console.log()
    return (
        <MDBCard>
            <div className='d-flex bd-highlight'>
                <div className="card-header card-title w-100">Add Supplier</div>
                <Link className='p-2 w-200 card-header card-title bd-highlight' to={'/SupplierTable/1'}><Button>Back</Button></Link>
            </div>
            <Form className="product1">
                <Row className="mb-1">
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Company Name</Form.Label>
                        <Form.Control type="text" value={companyname} autoComplete="off" onChange={(e) => setcompanyname(e.target.value)} placeholder='Enter Company Name' />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Supplier Name</Form.Label>
                        <Form.Control type="text" autoComplete="off" value={suppliername} onChange={(e) => setsuppliername(e.target.value)} placeholder='Enter Supplier Name' />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Phone Number</Form.Label>
                        <Form.Control type="number" autoComplete="off" onChange={(e) => setphonenumber(e.target.value)} placeholder='Enter Phone Number' />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">GST Number</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => { setgstno(e.target.value); setgstNumber(e.target.value) }} placeholder='Enter GST Number' />
                    </Form.Group>
                    <a className="btn btn-sm ertere" onClick={getgstdetails}>Get Gst Details</a>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Supplier Type</Form.Label>
                        <Form.Select onChange={(e) => setcolourtype(e.target.value)}>
                            <option>--Select--</option>
                            <option value="purchase">Purchase</option>
                            <option value="rawmaterial">Raw Material</option>
                            <option value="semifinishedgoods">Semi Finished Goods</option>
                            <option value="finishgoods">Finish Goods</option>
                            <option value="packingmaterial">Packing Material</option>
                            <option value="expenses">Expenses</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">OTP Login</Form.Label>
                        <Form.Select value={emailLoginStatus} name='emailLoginStatus' onChange={(e) => setemailLoginStatus(e.target.value)}>
                            <option>--Select--</option>
                            <option value={'Enable'}>Enable</option>
                            <option value={'Disable'}>Disable</option>
                        </Form.Select>
                    </Form.Group>
                    {emailLoginStatus === 'Disable' ?
                        <>
                            <Form.Group as={Col} md="6" controlId="formBasicName">
                                <Form.Label className="label">Email</Form.Label>
                                <Form.Control type="text" autoComplete="off" onChange={(e) => setemail(e.target.value)} placeholder='Enter Gmail' />
                            </Form.Group>
                        </>
                        : <>
                            <Form.Group as={Col} md="4" controlId="formBasicName">
                                <Form.Label className="label">Email</Form.Label>
                                <Form.Control type="text" autoComplete="off" disabled={verifydone == "" ? false : opttimeout == "" ? true : false} onChange={(e) => setemail(e.target.value)} placeholder='Enter Gmail' />
                            </Form.Group>
                            {verifydone == '' ?
                                <Form.Group as={Col} md="2" controlId="formBasicName">
                                    <Form.Label className="cname2"></Form.Label>
                                    {/* <Button onClick={() => sendemail(email)}>OTP Generate</Button> */}
                                    <div style={{marginTop:'27px'}}>
                            <span style={{color:'white',border:'0.5px solid #ededed',padding:'6px',background:'rgb(43 177 39)',borderRadius:'6px',cursor:'pointer'}} onClick={() => sendemail(email)}>OTP&nbsp;Generate</span>
                            </div>

                                </Form.Group> : opttimeout == "" ? <Form.Group as={Col} md="1" controlId="formBasicName">
                                    <Form.Label className="cname2"></Form.Label>
                                    <span class="snackbar_circle"><span class="checkmark">L</span></span>
                                </Form.Group> : <Form.Group as={Col} md="1" controlId="formBasicName">
                                    <Form.Label className="cname2"></Form.Label>
                                    {/* <Button onClick={() => sendemail(email)}>OTP Generate</Button> */}
                                    <div style={{marginTop:'27px'}}>
                            <span style={{color:'white',border:'0.5px solid #ededed',padding:'6px',background:'rgb(43 177 39)',borderRadius:'6px',cursor:'pointer'}} onClick={() => sendemail(email)}>OTP&nbsp;Generate</span>
                            </div>

                                </Form.Group>}
                            {status !== '' ? verifydone == '' && opttimeout == '' ?
                                <Row>

                                    <Form.Group as={Col} md="1" controlId="formBasicName">
                                        <Form.Label className="cname2">Verification Code*</Form.Label>
                                        <Form.Control type="text" autoComplete="off" onChange={(e) => setsendotp(e.target.value)} placeholder="Enter OTP" />
                                    </Form.Group>

                                    <Form.Group as={Col} md="1" controlId="formBasicName">
                                        <Form.Label className="cname2"></Form.Label>
                                        <Button onClick={() => verifyemail(email, sendotp)}>Verify</Button>

                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="formBasicName">

                                    </Form.Group></Row> : "" : ""}
                        </>}
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Address</Form.Label>
                        <Form.Control as="textarea" value={address} autoComplete="off" onChange={(e) => setaddress(e.target.value)} rows={3} name='address' placeholder='Enter Your Address' />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Country</Form.Label>
                        <Select value={countryoptions} isDisabled />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">State</Form.Label>
                        <Select options={statedata} onChange={(e) => onSelect(e)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">State Code</Form.Label>
                        <Form.Control type="text" value={state_code} readOnly />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">City</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setcities(e.target.value)} placeholder='Enter City' />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Zip Code</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setzipcode(e.target.value)} placeholder='Enter Zip Code' />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Account No</Form.Label>
                        <Form.Control type="number" autoComplete="off" onChange={(e) => setaccountno(e.target.value)} placeholder='Enter Account No' />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Bank Name</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setbankname(e.target.value)} placeholder=' Enter Bank Name' />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Branch Name</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setbranch(e.target.value)} placeholder="Enter Branch Name" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">IFSC Code</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setifsc(e.target.value)} placeholder="Enter IFSC Code" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Opening Balance</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setopeningbal(e.target.value)} placeholder='Enter ' />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Opening Balance Type</Form.Label>
                        <Form.Select onChange={(e) => setopeningbaltype(e.target.value)} >
                            <option value="debit">Debit</option>
                            <option value="credit">Credit</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Opening Balance Date</Form.Label>
                        <Form.Control type="date" value="2023-04-01" onChange={(e) => setopeningbaldate(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Password</Form.Label>
                        <Form.Control type="text" onChange={(e) => setPassword(e.target.value)} placeholder='Enter Password' />
                    </Form.Group>

                    <br></br>
                    <div className="row">
                        <div className="col-6 card">
                            {/* <label>Adresss</label> */}
                            {addresses.map((address, index) => (
                                <div key={index}>
                                    <div className="flexleft"> <label>Address</label><button type='button' className="btn btn-sm red white delete" onClick={() => removeAddress(index)}>
                                        X
                                    </button></div>
                                    <textarea
                                        name='supplier_delivery_address'
                                        className="form-control"
                                        value={address.supplier_delivery_address}
                                        onChange={(e) => handleChange(index, e)}
                                    ></textarea>
                                    <label className="form-label">Statename</label>
                                    <input type="text" onChange={(e) => handleChange(index, e)} name='state' className="form-control" value={address.state} />
                                    <label className="form-label">Pincode</label>
                                    <input type="number" onChange={(e) => handleChange(index, e)} name='pincode' className="form-control" value={address.pincode} />



                                </div>
                            ))}
                            <div className="row">
                                <div className="col">
                                    <button type='button' className="btn btn-sm btn-primary" onClick={addAddress}>
                                        +
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Row>
                {/* <Button variant="primary" onClick={submitdata} >Submit</Button> */}

                <Button variant="primary" style={{ width: 'auto' }} onClick={submitdata} disabled={isLoading}>
                    {isLoading ? 'Loading...' : 'Submit'}
                </Button>
            </Form>
        </MDBCard>
    )
}