import React from 'react'
import { Link } from 'react-router-dom';
import { Mydatatable } from '../Master/Product/Mydatatable';

export const Newdc = () => {


    let header = [
        { "label": "Id", "field": "id" },
        { "label": "Customer Name      ", "field": "distributorname" },
    
        { "label": "DC Date", "field": "dcdate" },
        { "label": "Tax Value", "field": "taxvalue" },
        { "label": "Gross Amount", "field": "gross_amount" },
        { "label": "Grand Total", "field": "grandtotal" },
        // { "label": "Gst Number", "field": "gst_Number" },
    //  { "label": "taxvalue", "field": "taxvalue" },
    { "label": "Created Date", "field": "createddate" },
    { "label": "Created Time", "field": "createdtime" },
    { "label": "Created By", "field": "createbyname" },
    // { "label": "Updated By", "field": "updatedbyname" },
        { "label": "Action", "field": "action","subaction1":<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg> }
      ]; 
      
      const sumofkeys=[{"first":'grandtotal'} ]


    //   "id": 1,
    //   "contactname": "omkar",
    //   "taxtype": "WithTax",
    //   "buyerorderdate": "2023-12-01T00:00:00.000+00:00"
{/* <Route path="/DeliveryChallanTable/:pointindex" element={<Newdc />} />
                  <Route path="/DeliveryChallanCreate/:id" element={<DCCreate />} />
                  <Route path="/DeliveryChallanCreate" element={<DCCreate1 />} />
                  <Route path="/DeliveryChallanView/:id/:pointindex" element={<DCView />} />
                  <Route path="/DeliveryChallanCreate" element={<DC1Create />} /> */} 


  return (
<>
<Link to='/DeliveryChallanCreatesopen' className="btn btn-sm btn-primary" style={{width:'12%'}}>SO&nbsp;Pending</Link>
<Mydatatable
    head={header}
    title={"Delivery Challan  "}
    axiostabledata={'dc'}
    Add={"Add DC"}
    // Edit={"/pedit"}
    Edit={"/DeliveryChallanView"}
    Create={'/DeliveryChallanCreate'}
     Sumofkeys={sumofkeys}
    View={"/DeliveryChallanView"}
    />


</>

  


  )
}
