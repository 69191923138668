import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { BiUser, BiLockOpen } from 'react-icons/bi'
import AuthService from "../services/auth.service";
import Swal from "sweetalert2";
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from "axios";
import { BASE_URL } from "../services/Api";

export const getdata = () => {


  return JSON.parse(localStorage.getItem('user')).username;

  // localStorage.setItem("username23",username23)
}
export const getroles = () => {
  return JSON.parse(localStorage.getItem('user')).roles;

  // localStorage.setItem("username23",username23)
}

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};


const Login = () => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = 'font-size 2s';
    element.style.fontSize = '30px';
    element.style.fontWeight = '600';
    // font-weight: 600;
    setTimeout(() => {
       
      document.getElementById("typer1").classList.add('promote');
      element.style.fontSize = '24px';
    }, 1000);
  };
  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false)
  const [sendemaildata, setsendemaildata] = useState('');
  const [status, setstatus] = useState(false);
  const [status1, setstatus1] = useState(false);
  const [verifydone, setverifydone] = useState('');
  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const sendemail = (e) => {
    setstatus(true);
    setPassword('');

    axios.put(BASE_URL + 'auth/otp', { "email": e }).then(res => {
      if (res.data == 'Your email id not available') {
        alert('Your Email Id Not Valid')
      } else {
        setsendemaildata(res.data);
      }
    }).catch(error => {
      alert('You are not Authorized for OTP Login')
  });
  };
  const sendemail1 = (e) => {

    setstatus1(true);
    axios.put(BASE_URL + 'auth/otp', { "email": e }).then(res => {
      if (res.data == 'Your email id not available') {
        alert('Your Email Id Not Valid')
      } else {
        setsendemaildata(res.data);
      }
    })
  };

  const handleLogin = (e) => {

    e.preventDefault();
    let logindata = { "email": username, "otp": verifydone }
    /* OTP Is Blank */
    if (verifydone == '' && password == '') {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Please Enter All The Fields",
        showConfirmButton: false,
        timer: 1400
      });
    }

    /* OTP Submit */
    if (verifydone) {
      AuthService.otplogin({ "email": username, "otp": verifydone }).then(res => {

        navigate("/");
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "You are Logged in",
          showConfirmButton: false,
          timer: 1400
        });
        window.location.reload();
      }).catch(err => {
        /* OTP WRONG */
        if (err.code == 'ERR_BAD_REQUEST') {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Please Enter Valid OTP",
            showConfirmButton: false,
            timer: 1400
          });
        }
      })
    } else if (password) {
      setIsLoading(true);
      AuthService.login(username, password).then(
        () => {
           navigate("/");
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "You are Logged in",
            showConfirmButton: false,
            timer: 1400
          });
          window.location.reload();


        },
        (error) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "You are not Logged in",
            showConfirmButton: false,
            timer: 1400
          });
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      ).catch(error => {
        console.error('Error adding  :', error);
        if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `
            <div>
              <p id="issueText">Why do I have this issue?</p>
              <ul>
          
              <li id="typer1">You might have an internet issue</li>
              </ul>
            </div>
          `,
          didOpen: () => {
            applyFontSizeTransition('issueText');
          }
        });
      }
      })
      .finally(() => {
        setIsLoading(false); 
      });
    }
  };

  const windowSizew = window.innerWidth;
  const winh = window.innerHeight
 


  return (
    <div>
      <div className="navwe  black12"   >
        <ul>
          <li>   <li>  <img src={require('../Login/logo111.png')} className="ee" /></li></li>
        </ul>
        <ul className="rtr">

        </ul>
      </div>
      <Form onSubmit={handleLogin} ref={form} >

        <div className="container ttdf" data-aos="fade">
          <div className="card ggfg">
            <div className="card-header">
              <h2 className="log">Login Swift</h2>
            </div>
            <div className="card-body">

              {/* <form> */}
              <label className="form-label">E-mail</label>
              <input type="text"   className="form-control" placeholder="Enter a valid Email address"
                value={username}
                onChange={onChangeUsername} />

              <br></br>
              {status == false ?
                <><label className="form-label">Password</label>
                  <input type={
                    showPassword ? "text" : "password"
                  }  placeholder="Enter password"
                    value={password}
                    onChange={onChangePassword} validations={[required]} className="form-control" /></> : <><label className="form-label">OTP</label><input type="text" value={verifydone} onChange={(e) => setverifydone(e.target.value)} className="form-control" placeholder="Enter OTP" /></>}
              <br></br>
              {status == true ? "" :
                <div className="flex between">

                  <input id="check"
                    type="checkbox"
                    value={showPassword}
                    onChange={() =>
                      setShowPassword((prev) => !prev)
                    } className="form-radio" />&nbsp;
                  <span className="form-label">Show password</span>
                </div>
              }

              <br></br>
              {/* <br></br> */}
              {status == false ?
                <div onClick={() => sendemail(username)} style={{ width: 120, padding: 8, cursor: 'pointer', background: '#164194', color: 'white', fontWeight: 600 }}>Login Via OTP</div> :
                status1 == false ? <p>If You Have Not Received OTP Please Click Here To <Link onClick={() => sendemail1(username)}>Resend</Link></p> : ""}
              {/*</form>

<br></br> */}
              <div className="row">
                <div className="col-12">
                  {/* <button className="btn  er" ><b>Login</b></button> */}
                  <button className="btn  er"  disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Login'}
        </button>
                </div>
              </div>



            </div>
          </div>
        </div>
      </Form>


    </div>
  );
};

export default Login;

