import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import WorkOrderService from "../../services/WorkOrderService";
import UserService from "../../services/user.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MDBCard } from "mdbreact";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import axios from "axios";
import ProductService from "../../services/ProductService";
import { useDispatch, useSelector } from "react-redux";
import { estimateddelivery, estimateddeliverydata } from "../../../redux/Action/PrimaryOrderView";
import { BsFillEyeFill } from "react-icons/bs";
import Swal from "sweetalert2";
import AuthService from "../../services/auth.service";

export default function View() {
  const {   pointindex  } = useParams();

  /* Primary Order Id */
  const { id } = useParams();
  const [remarks, setremarks] = useState('')
  const [posts, setPosts] = useState([]);
  const [Product, setproduct] = useState([]);
  const [productdata, setdata] = useState([]);
  const [time, settime] = useState([]);
  const [tradename, settrade] = useState([]);
  const [distaddress, setdistaddress] = useState('');
  const [primaryOrderItems, setproduct1212] = useState([]);
  const [totalPcsQuantity, setTotalPcsQuantity] = useState(0);
  const [totalnet, settotalnet] = useState();
  const [grossfull, setgrossfull] = useState();
  const [gstvalue, setgstvalue] = useState();
  const [amount, setamount] = useState();
  const [kg, setkg] = useState();
  //////////////////
  const [sumbox, setsumbox] = useState(0);
  const [sumofcorp, setcorp] = useState(0);
  const [sumofcorpcs, setcorpc] = useState(0);
  const [boxamt, setboxsmt] = useState(0);
  const [amt, setamt] = useState(0);
  const [kg1, setkg1] = useState(0);
  const [kg2, setkg2] = useState(0);
  const [kg3, setkg3] = useState(0);

  const [c1, setc1] = useState(0);
  const [c2, setc2] = useState(0);
  const [c3, setc3] = useState(0);

  const [n1, setn1] = useState(0);
  const [n2, setn2] = useState(0);
  const [n3, setn3] = useState(0);

  /* Redux */
  const dispatch = useDispatch();
  const primaryorderView = useSelector((state) => state.primaryorderview);
  const stdkgs = primaryorderView.length && primaryorderView.map(user=>(Number(user.quantity_placed_kg))).reduce((prev,next)=>(prev-(-next)));
  const stdpcs = primaryorderView.length && primaryorderView.map(user=>(Number(user.quantity_placed))).reduce((prev,next)=>(prev-(-next)));
  // console.log("mello",stdkgs)

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  /* Navigator */
  const navigate = useNavigate();

  useEffect(() => {

    UserService.getprimaryorderedit(id).then(res => {
      // console.log("mello", res.data)
      let ram = '';
      const naming = res.data.distributor
      settrade(naming.tradeName);
      setPosts(res.data);
      if(naming.deliveryAddress == ""){
        return
      } else {
        (naming.deliveryAddress).map(item=>{
          if(item.id == res.data.deliveryAddress){
            setdistaddress(item);
          }
        })
      }
      // console.log(res.data.distributor);

      setproduct(res.data)
      // ProductService.getProduct().then((response) => {

      //   var aaaa = response.data;
      //   setdata(aaaa);
      //   // console.log(res.data.primaryOrderItems);
      //   // console.log(response.data);
      //   setproduct1212(res.data.primaryOrderItems)
      //   ram = res.data.primaryOrderItems.map((item) => {
      //     const filterman = response.data.filter((item11) => item11.id == item.productId).map((item) => item.productName);

      //     return { ...item, name: filterman }


      //   })

      //   settime(ram);
      //   //  console.log(ram);



      // })

    })
    dispatch(estimateddelivery(id));
  }, [])
  const changeinplan = () => {
    // console.log("hello");
    navigate(`/Newpo/${pointindex}`)
  }
  const [prodid, setprodid] = useState('');
  const [estimatedDays, setestimatedDays] = useState('');
  const [productid, setproductid] = useState('');
  const datacall = (e) => {
    console.log(e)
    setprodid(e.poid);
    setproductid(e.productId);
  }

  const submitForm = () => {
    let pid = productid;
    let days = estimatedDays;
    let primaryItemId = prodid;

    // console.log("mello",id,prodid,estimatedDays,productid);
    UserService.getprimaryestimatedays(id, pid, days, primaryItemId).then(res => {
      // console.log("Response",res);
      window.location.reload();
    })
  }
  /* Product View */
  const [productdata1, setProductdata1] = useState('');

  const[imagedisplay,setdiplay]=useState([])

  const productdetails = (e) => {
    ProductService.getProductEdit(e).then(res => {
      setProductdata1(res.data);
      setdiplay(res.data.productImages);

    })
  }

  useEffect(() => {
    calculateSum()
  }, [primaryOrderItems]);

  const calculateSum = () => {
    console.log(primaryOrderItems)
    let prmy = primaryOrderItems.map((order) => {
      const measurement = parseFloat(order.measurement);
      return isNaN(measurement) || !isFinite(measurement) ? 0 : measurement;
    }).reduce((prev, curr) => prev + curr, 0);

    console.log(prmy);

    prmy = parseFloat(prmy.toFixed(2));
    setTotalPcsQuantity(prmy);
    ///////////////
    let totalnet = primaryOrderItems.map((order) => parseFloat(order.netAmount))
      .reduce((prev, curr) => prev + curr, 0);
    totalnet = parseFloat(totalnet.toFixed(2));
    settotalnet(totalnet)
    /////////////////////////////////
    let grossnet = primaryOrderItems.map((order) => parseFloat(order.grossamount))
      .reduce((prev, curr) => prev + curr, 0);

    grossnet = parseFloat(grossnet.toFixed(2));
    setgrossfull(grossnet)
    //////////////////////////////////////
    let gst = primaryOrderItems.map((order) => parseFloat(order.gstvalue))
      .reduce((prev, curr) => prev + curr, 0);
    let gt = parseFloat(gst.toFixed(2))
    setgstvalue(gt)
    ///////////////////////////////////////////
    let amt = primaryOrderItems.map((order) => parseFloat(order.total))
      .reduce((prev, curr) => prev + curr, 0);
    const tt = parseFloat(amt.toFixed(2))
    setamount(tt)
    /////////////////////////////////////
    let kg1 = primaryOrderItems
      .map((order) => {
        const parsedQty = parseFloat(order.qty);
        return isNaN(parsedQty) || !isFinite(parsedQty) ? 0 : parsedQty;
      })
      .reduce((prev, curr) => prev + curr, 0);

    const tt1 = parseFloat(kg1.toFixed(2));
    setkg(tt1);
    ///////////////////////////
    let amt12 = primaryOrderItems
      .map((order) => parseFloat(order.netAmount))
      .reduce((prev, curr) => prev + curr, 0);




    ///////box//////
    const kgCorporateProducts = primaryOrderItems.filter((order) => order.productType === "boxproduct")
    setsumbox(kgCorporateProducts.map((order) => parseFloat(order.measurement))
      .reduce((prev, curr) => prev + curr, 0))

    setboxsmt(primaryOrderItems.filter((order) => order.productType === "boxproduct").map((order) => parseFloat(order.total))
      .reduce((prev, curr) => prev + curr, 0))
    ////////corp/////////////////
    const sumkgCor = primaryOrderItems.filter((order) => order.productType === "corporateproduct")
    setcorp(sumkgCor.map((order) => parseFloat(order.measurement))
      .reduce((prev, curr) => prev + curr, 0))

    const sumpccor = primaryOrderItems.filter((order) => order.productType === "corporateproduct")
    setcorpc(sumpccor.map((order) => parseFloat(order.qty))
      .reduce((prev, curr) => prev + curr, 0))

    setamt(sumpccor.map((order) => parseFloat(order.total))
      .reduce((prev, curr) => prev + curr, 0))

    //////////kg/////////////

    const kgproductpc = primaryOrderItems.filter((order) => order.productType === "kgproduct")
    const time = kgproductpc.map((order) => parseFloat(order.qty))
      .reduce((prev, curr) => prev + curr, 0)
    const time2 = kgproductpc.map((order) => parseFloat(order.measurement))
      .reduce((prev, curr) => prev + curr, 0)
    const totalkgg = kgproductpc.map((order) => parseFloat(order.total))
      .reduce((prev, curr) => prev + curr, 0)
    setkg1(parseFloat(time).toFixed(2))
    setkg2(time2)
    setkg3(totalkgg)
    ////////////////cooker/////////////////
    const cooker = primaryOrderItems.filter((order) => order.productType === "cookerproduct")
    const pcs = cooker.map((order) => parseFloat(order.measurement))
      .reduce((prev, curr) => prev + curr, 0)
    setc1(pcs)
    const c3total = cooker.map((order) => parseFloat(order.total))
      .reduce((prev, curr) => prev + curr, 0)
    setc3(c3total)
    //////////////////nosh//////////////////////
    const noshing = primaryOrderItems.filter((order) => order.productType === "noshproduct")
    const nospcs = noshing.map((order) => {
      const measurement = parseFloat(order.measurement);
      return isNaN(measurement) || !isFinite(measurement) ? 0 : measurement;
    }).reduce((prev, curr) => prev + curr, 0);
    setn1(nospcs)
    const nospcs1 = noshing.map((order) => {
      const measurement1 = parseFloat(order.qty);
      return isNaN(measurement1) || !isFinite(measurement1) ? 0 : measurement1;
    }).reduce((prev, curr) => prev + curr, 0);
    setn3(nospcs1)

    const nospcs11 = noshing.map((order) => {
      const measurement1 = parseFloat(order.total);
      return isNaN(measurement1) || !isFinite(measurement1) ? 0 : measurement1;
    }).reduce((prev, curr) => prev + curr, 0);
    setn2(nospcs11)

    // const nostotal=noshing.map((order) => {parseFloat(order.total)})
    // .reduce((prev, curr) => prev + curr, 0)
    // setn2(nostotal)
    // console.log(nostotal)



  }

  const handledelete=(id)=>{
    console.log(id);
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      navigate(`/Newpo/${pointindex}`)
      if (result.isConfirmed) {
    
        UserService.getprimaryorderdelete(id).then(res=>{
  
          // window.location.reload();
       console.log(res.data);
     if(res.data==200||res.data==201){
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
        navigate(`/Newpo/${pointindex}`)
     }
        })
        
      }
    })
    }
    const user = AuthService.getCurrentUser();


  return (

    < >
      <MDBCard className="redd" data-aos="fade">
      {posts.orderStatus == true?(
                  <div className='d-flex bd-highlight'>
                  <div className="card-header card-title w-100">Primary Order View</div>
                  {/* <Link className='p-2 w-200 card-header card-title bd-highlight' to={'/PrimaryOrderprint/' + id}><Button></Button></Link> */}
                  <Link className='prox btn'to={'/PrimaryOrderprint/' + id}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-printer"><polyline points="6 9 6 2 18 2 18 9"/><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"/><rect x="6" y="14" width="12" height="8"/></svg></Link>

                  <Link className='prox btn' to={`/Newpo/${pointindex}`}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"/><polyline points="12 19 5 12 12 5"/></svg></Link>
             
                </div>
        ):(
          <div className='d-flex bd-highlight'>
          <div className="card-header card-title w-100">Primary Order View</div>
          {/* <Link className='p-2 w-200 card-header card-title bd-highlight' to={'/PrimaryOrderprint/' + id}><Button></Button></Link> */}
          <Link className='prox btn'to={'/PrimaryOrderprint/' + id}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-printer"><polyline points="6 9 6 2 18 2 18 9"/><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"/><rect x="6" y="14" width="12" height="8"/></svg></Link>
          <Link className='cwf prox btn'to={'/SalesOrderCreate/' + id}>SO</Link>
          <Link className='cwf prox btn'to={'/PrimaryOrderUpdate/' + id + '/' + pointindex}>Edit</Link>

{/* <Link className='p-2 w-200 card-header card-title bd-highlight' ></Link> */}
          {/* <Link className='p-2 w-200 card-header card-title bd-highlight' to={'/PrimaryOrderTable'}><Button>Back</Button></Link> */}
          <Link className='prox btn' to={`/Newpo/${pointindex}`}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"/><polyline points="12 19 5 12 12 5"/></svg></Link>
          {user.roles[0] === 'ROLE_ADMIN' &&<a className='delete  btn' onClick={()=>handledelete(id)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg></a>}
     
        </div>
        )}
        {/* <h1>Primary Order View</h1> */}
        <Row>
          <Col>
            <label className="form-label">Distributor Name</label>
            <input type="text" className="form-control" style={{ border: "none" }} value={tradename} />
          </Col>
          <Col>
            <label className="form-label">Distributor Address</label>
            <input type="text" className="form-control" style={{ border: "none" }} value={distaddress.delivery_address} />
          </Col>
          </Row>
          <Row>
          <Col>
            <label className="form-label">Distributor PinCode</label>
            <input type="text" className="form-control" style={{ border: "none" }} value={distaddress.pincode} />
          </Col>
          <Col>
            <label className="form-label">Distributor State</label>
            <input type="text" className="form-control" style={{ border: "none" }} value={distaddress.state} />
          </Col>
        </Row>
        <br></br>
        <Table striped responsive hover className=" thrtable " size="sm">
        <thead className='p-3 mb-2 bg-success text-white'>
          <tr>
            <th style={{ width:'7%',fontSize:14 }}>ID</th>
            <th style={{ width:'7%',fontSize:14 }}>Product Name</th>
            <th style={{ width:'7%',fontSize:14 }}>Quantity</th>
            <th style={{ width:'7%',fontSize:14 }}>Quantity in kg</th>
            <th style={{ width:'7%',fontSize:14 }}>SQ</th>
            <th style={{ width:'7%',fontSize:14 }}>SQ kg</th>
            <th style={{ width:'7%',fontSize:14 }}>Rate</th>
            <th style={{ width:'7%',fontSize:14 }}>Trade Dis %</th>
            <th style={{ width:'7%',fontSize:14 }}>UOM</th>
            <th style={{ width:'7%',fontSize:14 }}>Gross Dis ₹</th>
            <th style={{ width:'7%',fontSize:14 }}>Scheme Dis %</th>
            <th style={{ width:'7%',fontSize:14 }}>Net Amount ₹</th>
            <th style={{ width:'7%',fontSize:14 }}>Gst Value ₹</th>
            <th style={{ width:'7%',fontSize:14 }}>Total ₹</th>
            <th style={{ width:'7%',fontSize:14 }}>Estimated delivery</th>
            <th style={{ width:'7%',fontSize:14 }}>View</th>
            <th style={{ width:'7%',fontSize:14 }}>Add estimated delivery</th>
          </tr>
          </thead>
          <tbody style={{overflowY:"scroll"}}>

          {
            primaryorderView.map((item, index) => {
              return <tr style={{ border: "1px solid #00000036" }}>
                <td style={{ lineHeight: "50px" }}>{index + 1}</td>
                <td>{item.productName}</td>
                <td>{item.measurement}</td>
                <td>{item.qty}</td>
                <td>{item.quantity_placed}</td>
                <td>{item.quantity_placed_kg}</td>
                <td>{item.dlp}</td>
                <td>{item.discount}</td>
                <td>{item.unitofmeasurement == "kg" ? "KG" : "PCS"}</td>
                <td>{item.grossamount}</td>
                <td>{item.discount1}</td>

                <td>{item.netAmount}</td>
                <td>{item.gstvalue}</td>
                <td>{item.total}</td>
                <td>{item.estimatedDays}</td>
                <td>
                  <div onClick={(e) => { handleShow1(e); productdetails(item.productId) }} style={{ textAlign: 'center', cursor: 'pointer', padding: '4px 8px' }} > <BsFillEyeFill style={{ color: 'var(--bs-link-color)' }} /></div>
                  <Modal
                    show={show1}
                    onHide={handleClose1}
                    backdrop="static"
                    keyboard={false}
                    size='lg'
                  >
                    <Modal.Header closeButton style={{ background: '#164194', color: 'white' }}>
                      <Modal.Title>Product Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                    {imagedisplay.map((user,index) => (
                <img src={"http://43.225.54.138:8080/scm/Images/" + user.name} className="images" style={{ width: '200px', height: '150px' }} />
              ))}
                      <Row className="mb-1">
                        <Form.Group as={Col} md="12" controlId="formBasicName">
                          <span style={{ fontSize: '18px', fontWeight: 600 }}>Product Name : - {productdata1.productName}</span>

                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Short Name : - {productdata1.shortName}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Ean Code No : - {productdata1.eanCode}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Standard Qty Per Box : - {productdata1.standardQtyPerBox}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Groups : - {productdata1.productGroup}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Category : - {productdata1.category}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>UOM Primary : - {productdata1.uomPrimary}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>UOM Secondary : - {productdata1.uomSecondary}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>DLP(₹) : - {productdata1.dlp}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>MRP(₹) : - {productdata1.mrp}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Capacity(ml) : - {productdata1.capacity}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Diameter : - {productdata1.diameter}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>HSN Code : - {productdata1.hsnCode}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Brand Name : - {productdata1.brand ? "Neelam" : ''}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Product Type : - {productdata1.productType}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>IGST% : - {productdata1.igst}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>CGST% : - {productdata1.cgst}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>SGST% : - {productdata1.sgst}</span>
                        </Form.Group>
                      </Row>
                    </Modal.Body>
                  </Modal>
                </td>
                <td>
                  <Button variant="primary" onClick={(e) => { handleShow(e); datacall(item) }}>+</Button>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Estimated Delivery</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label>Estimated Delivery</Form.Label>
                        <Form.Control type="text" onChange={(e) => { setestimatedDays(e.target.value) }} />
                      </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={submitForm}>Submit</Button>
                    </Modal.Footer>
                  </Modal>
                </td>
              </tr>
            })
          }
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          {primaryorderView.length !== 0 &&
          (
            <tr>
            <th></th>
            <th></th>
            <th className="bold" style={{ fontSize: 15 }}>{posts.totalQtyPcs || 0}</th>
            <th className="bold" style={{ fontSize: 15 }}>{(posts.totalQtyKg || 0).toFixed(2)}</th>
            <th className="bold" style={{ fontSize: 15 }}>{stdpcs || 0}</th>
            <th className="bold" style={{ fontSize: 15 }}>{stdkgs.toFixed(2) || 0}</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th className="bold" style={{ fontSize: 15 }}>{(posts.netValue || 0).toFixed(2)}</th>
            <th className="bold" style={{ fontSize: 15 }}>{(posts.taxAmount || 0).toFixed(2)}</th>
            <th className="bold" style={{ fontSize: 15 }}>{(posts.grossTotal || 0).toFixed(2)}</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          
        
        )}
         </tbody>
        </Table>
        <br></br>
        <br></br>
     
        <table>
          <tr>
            {primaryorderView.length !== 0 && (
       <>
       <tr>
         <th className='namee'>Summary</th>
         <th className='namee'>Total Qty(KG)</th>
         <th className='namee'>Total Qty(Pcs)</th>
         <th className='namee'>Total price</th>
       </tr>
       <tr>
         <th>Box Product</th>
         <th></th>
         <th>{posts.boxProductTotalQtyPcs || 0}</th>
         <th>{(posts.boxProductTotalprice || 0).toFixed(2)}</th>
       </tr>
       <tr>
         <th>KG Product</th>
         <th>{(posts.kgProductTotalQtyKg || 0).toFixed(2)}</th>
         <th>{posts.kgProductTotalQtyPcs || 0}</th>
         <th>{(posts.kgProductTotalprice || 0).toFixed(2)}</th>
       </tr>
       <tr>
         <th>Corporate Product</th>
         <th></th>
         <th>{posts.corporateProductTotalQtyPcs || 0}</th>
         <th>{(posts.corporateProductTotalprice || 0).toFixed(2)}</th>
       </tr>
       <tr>
         <th>Cooker Product</th>
         <th></th>
         <th>{posts.cookerProductTotalQtyPcs || 0}</th>
         <th>{(posts.cookerProductTotalprice || 0).toFixed(2)}</th>
       </tr>
       <tr>
         <th>Nosh Product</th>
         <th></th>
         <th>{posts.noshProductTotalQtyPcs || 0}</th>
         <th>{(posts.noshProductTotalprice || 0).toFixed(2)}</th>
       </tr>
       <tr>
         <th>Total</th>
         <th>{(posts.totalQtyKg || 0).toFixed(2)}</th>
         <th>{posts.totalQtyPcs || 0}</th>
         <th>{(posts.grossTotal || 0).toFixed(2)}</th>
       </tr>
     </>
     
            )}
          </tr>



        </table>

        <br></br>
        <Button variant="primary" type="button" onClick={changeinplan}>Back</Button>
      </MDBCard>
    </ >
  )
}

