import React, { useState, useEffect } from "react";
import { createRoot } from 'react-dom/client';
import { Routes, Route, Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { jwtDecode } from "jwt-decode";

import AuthService from "./Component/services/auth.service";
import Login, { getdata } from "./Component/Login/Login";
import EventBus from "./Component/services/EventBus";
import { AiOutlineFullscreenExit, AiOutlineMail, AiOutlineBell } from "react-icons/ai";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Company from "./Component/Master/Company/Company";
import { SideBar } from "./Component/SideBar/SideBar";
import DashBoard from "./Component/Master/DashBoard/DashBoard";
import PCreate from "./Component/Master/Product/PCreate";
import PView from "./Component/Master/Product/Pview";
import Pview from "./Component/Master/Product/Pview";
import Brand from "./Component/Master/Brand/Brand";
// import New from "./Component/Master/New";
// import New2 from "./Component/Master/New2";

import BrandCreate from './Component/Master/Brand/Create';
import BrandEdit from './Component/Master/Brand/Edit';
import WareHouse from "./Component/Master/WareHouse/WareHouse";
import Staff from "./Component/Master/Staff/Staff";
import STable from "./Component/Master/Staff/STable";
import SEdit from "./Component/Master/Staff/SEdit";
import PTable from "./Component/Master/Product/PTable";
import PEdit from "./Component/Master/Product/PEdit";
import WareTable from "./Component/Master/WareHouse/WareTable";
import WareEdit from "./Component/Master/WareHouse/WareEdit";
import WareView from './Component/Master/WareHouse/View';
import RetailerCreate from './Component/Master/Retailer/Create';
import RetailerTable from './Component/Master/Retailer/Table';
import RetailerEdit from './Component/Master/Retailer/Edit';
import RetailerView from './Component/Master/Retailer/View';
import DistrbutorTable from './Component/Master/Distrbutor/Table';
import DistrbutorCreate from './Component/Master/Distrbutor/Create';
import DistrbutorEdit from './Component/Master/Distrbutor/Edit';
import DistrbutorView from './Component/Master/Distrbutor/View';
// import Newopening from "./Component/Master/Opening Stock/Newopening1";

import WorkOrderTable from './Component/Master/WorkOrder/Table';
import WorkOrderCreate from './Component/Master/WorkOrder/Create';
import WorkOrderEdit from './Component/Master/WorkOrder/Edit';
import WorkOrderView from './Component/Master/WorkOrder/View';
import PrimaryOrderUpdate from './Component/Master/PrimaryOrder/Update';
import MinimumStock from "./Component/Master/Warehouse Minimum Stock/MinimumStock";


import PrimaryOrderTable from './Component/Master/PrimaryOrder/Table';
import PrimaryOrderCreate from './Component/Master/PrimaryOrder/Create';
import PrimaryOrderView from './Component/Master/PrimaryOrder/View';
import Create1 from "./Component/Master/Distrbutor/Createnew";
import Table from "./Component/Master/TemporaryRetailer/Table";
import View from "./Component/Master/TemporaryRetailer/View";
import Create from "./Component/Master/TemporaryRetailer/Create";
import Map from "./Component/Master/Staff/Map";
import MapTable from "./Component/Master/Staff/MapTable";
import Under12 from "./Component/SideBar/Under12";
import Print from "./Component/Master/PrimaryOrder/Print";
import Logout from "./Component/Login/Logout";
import Swal from "sweetalert2";
import Aos from "aos";
import New from "./Component/Master/New";
import New2 from "./Component/Master/New2";
import Ptable from "./Component/Master/PrimaryOrder/Ptable";
import Tabledis from "./Component/Master/Distrbutor/Tabledis";
import Retable from "./Component/Master/Retailer/Retable";
import Salesorder from "./Print/Salesorder";
import DeliveryNote from "./Print/DeliveryNote";
import InvoiceFormat from "./Print/InvoiceFormat";
import ReceiptNote from "./Print/ReceiptNote";
import Purchaseorder from "./Print/Purchaseorder";
import INvoice from "./Print/INvoice";
import Salesreturn from "./Print/Salesreturn";
import Purchasereturn from "./Print/Purchasereturn";
import Stafftable from "./Component/Master/Staff/Stafftable";
import Brandtable from "./Component/Master/Brand/Brandtable";
import Temtable from "./Component/Master/TemporaryRetailer/Temtable";
import Worktable from "./Component/Master/WorkOrder/Worktable";
import PView11 from "./Component/Master/Product/Pview11";
import { Newtable } from "./Component/Master/Product/Newtable";
import { Newstafftable } from "./Component/Master/Staff/Newstafftable";
import Newretailer from "./Component/Master/Retailer/Newretailer";
import { Newwo } from "./Component/Master/WorkOrder/Newwo";
import Newpo from "./Component/Master/PrimaryOrder/Newpo";
import Newdis from "./Component/Master/Distrbutor/Newdis";
import OpenCreate from "./Component/Master/Opening Stock/OpenCreate";
import SupplierTable from './Component/Supplier/Table';
import SupplierCreate from './Component/Supplier/Create';
import SupplierEdit from './Component/Supplier/Edit';
import SupplierView from './Component/Supplier/View';
import SupplierContact from './Component/Supplier/Contact';
import POTable from './Component/PO/Table';
import POCreate from './Component/PO/Create';
import POEdit from './Component/PO/Edit';
import POView from './Component/PO/View';
import PurchaseTable from './Component/Purchase/Table';
import PurchaseCreate from './Component/Purchase/Create';
import PurchaseCreate1 from './Component/Purchase/Create1';
import PurchaseEdit from './Component/Purchase/Edit';
import PurchaseView from './Component/Purchase/View';
import SupplierDeliveryNoteTable from './Component/Supplier delivery note/Table';
import SupplierDeliveryNoteCreate from './Component/Supplier delivery note/Create';
import SupplierDeliveryNoteEdit from './Component/Supplier delivery note/Edit';
import SupplierDeliveryNoteView from './Component/Supplier delivery note/View';
import MaterialReceiptNoteTable from './Component/Material Receipt Note/Table';
import MaterialReceiptNoteCreate from './Component/Material Receipt Note/Create';
import MaterialReceiptNoteCreate1 from './Component/Material Receipt Note/Create1';
import MaterialReceiptNoteEdit from './Component/Material Receipt Note/Edit';
import MaterialReceiptNoteView from './Component/Material Receipt Note/View';
import PurchaseReturnTable from './Component/PurchaseReturn/Table';
import PurchaseReturnCreate from './Component/PurchaseReturn/Create';
import PurchaseReturnUpdate from './Component/PurchaseReturn/Edit';
import PurchaseReturnView from './Component/PurchaseReturn/View';
import SalesOrderTable from './Component/SalesOrder/Table';
import SalesOrderCreate from './Component/SalesOrder/Create';
import SalesOrderEdit from './Component/SalesOrder/Edit';
import SalesOrderView from './Component/SalesOrder/View';
import SalesOrderCreate1 from './Component/SalesOrder/Create1';
import DCTable from './Component/Delivery Challan/Table';
import DCCreate from './Component/Delivery Challan/Create';
import DCCreate1 from './Component/Delivery Challan/Create1';
import DCView from './Component/Delivery Challan/View';
import DC1Create from './Component/DC/Create';
import CBranch from "./Component/CompanyBranch/CBranch";
import CompanyBranchCreate from './Component/CompanyBranch/Create';
import CompanyBranchEdit from './Component/CompanyBranch/Edit';
import CompanyBranchView from './Component/CompanyBranch/View';
import SalesTable from './Component/Sales/Table';
import SalesCreate from './Component/Sales/Create';
import SalesCreate1 from './Component/Sales/Create1';
import SalesEdit from './Component/Sales/Edit';
import SalesView from './Component/Sales/View';
import SalesReturnTable from './Component/SalesReturn/Table';
import SalesReturnCreate from './Component/SalesReturn/Create';
import SalesReurnUpdate from './Component/SalesReturn/Update';
import SalesRetunView from './Component/SalesReturn/View';
import DistributorReport from "./Component/Reports/DistributorReport";
import RetailerReport from "./Component/Reports/RetailerReport";
import { Newpurchase } from "./Component/Purchase/Newpurchase";
import Openingedit from "./Component/Master/Opening Stock/Openingedit";
import Newpurchasereturn from "./Component/PurchaseReturn/Newpurchasereturn";
import Newmrn from "./Component/Material Receipt Note/Newmrn";
import Newsalesorder from "./Component/SalesOrder/Newsalesorder";
import Newsalestable from "./Component/SalesReturn/Newsalestable";
import { Newtem } from "./Component/Master/TemporaryRetailer/Newtem";
import Newopening from "./Component/Master/Opening Stock/Newopening";
import { Newdel } from "./Component/Supplier delivery note/Newdel";
import Newsales from "./Component/Sales/Newsales";
import { Newdc } from "./Component/Delivery Challan/Newdc";
import Newbrand from "./Component/Master/Brand/Newbrand";
import Newwarehouse from "./Component/Master/WareHouse/Newwarehouse";
import { Newbranch } from "./Component/CompanyBranch/Newbranch";
import Newsupplier from "./Component/Supplier/Newsupplier";
import { Newpurchaseorder } from "./Component/PO/Newpurchaseorder";
// import Mydatatable from "./Component/Master/Product/datatable";
// import Newmasterproduct from "./Component/Master/Product/newmasterproduct";
// import { Under } from "./Under";
import Minstock from "./Component/Reports/Minstock";
// import Testpagination from "./Component/Reports/Testpagination";
import DistributorStockrp from "./Component/Reports/DistributorStockrp";
import TransportReport from "./Component/Reports/TransportReport";
import RetailerMonthReport from "./Component/Reports/RetailerMonthReport";
import Outwarddis from "./Component/Master/Opening Stock/Outwarddis";
import DistributorMonthReport from "./Component/Reports/DistributorMonthReport";
import Distoutwordtable from './Component/Master/DisOutWord/Table'
import Disoutwordcreate from './Component/Master/DisOutWord/Disoutwordcreate';
import Disoutwardupdate from './Component/Master/DisOutWord/Disoutwardupdate';
import MininmumStockReport from "./Component/Reports/MininmumStockReport";
import GodownStockReport from "./Component/Reports/GodownStockReport";
import axios from "axios";
import { BASE_URL } from "./Component/services/Api";
import authHeader from "./Component/services/auth-header";
import { BiBell } from "react-icons/bi";
import AttendanceTable from "./Component/Attendance/AttendanceTable";
import AttendanceCreate from './Component/Attendance/Create';
import Attendace_Create from './Component/Attendance/AttendanceCreate';
import HolidayTable from './Component/Attendance/Holiday/Table';
import HolidayCreate from './Component/Attendance/Holiday/Create';
import HolidayUpdate from './Component/Attendance/Holiday/Update';
import HolidayView from './Component/Attendance/Holiday/View';
import SecondaryOrderReports from "./Component/Reports/SecondaryOrderreports";
import Primaryorderreports from "./Component/Reports/Primaryorderreports";
import SalesExpenseReports from "./Component/Reports/SalesExpenseReports";
import { MobileProductlunch } from "./Fileuploadformobile/MobileProductlunch";
import { Arealaunchmobile } from "./Fileuploadformobile/Arealaunchmobile";
import AssamblyCreate from './Component/Assambly/Create';
import BomTable from './Component/Master/BOM/Table';
import BomCreate from './Component/Master/BOM/Create';
import BomView from './Component/Master/BOM/View';
import PendingOrderVsGodownStockComponents from "./Component/Reports/PendingOrderVsGodownStockComponents";
import WorkorderToSalesorder from "./Component/SalesOrder/WorkorderToSalesorder";
import RetailerCreateDC from "./Component/Delivery Challan/RetailerCreateDC";
import RetailerCreatesales from "./Component/Sales/RetailerCreatesales";
import ProductBarCodeTable from './Component/BarCode/ProductBarcode/Table';
import ProductBarCodeCreate from './Component/BarCode/ProductBarcode/Create';
// import ProductBarCodePrint from './Component/BarCode/ProductBarcode/ProductBarCodePrint';
import SetBarCodeTable from './Component/BarCode/SetBarCode/Table';
import SetBarCodeCreate from './Component/BarCode/SetBarCode/Create';
// import SetBarCodeView from './Component/BarCode/SetBarCode/Print';
import CartonBarCodeTable from './Component/BarCode/CartonBarCode/Table';
import CartonBarCodeCreate from './Component/BarCode/CartonBarCode/Create';
import CartonBarCodePrint from './Component/BarCode/CartonBarCode/Print';
import Cartoonview from './Component/BarCode/CartonBarCode/Cartoonview';
import MasterCartonTable from './Component/BarCode/Mastercarton/Mastercartoontable';
import MasterCartonCreate from './Component/BarCode/Mastercarton/Mastercreate';
import Mastercartoonview from './Component/BarCode/Mastercarton/Mastercartoonview';
import Mastercartoonedit from "./Component/BarCode/Mastercarton/Mastercartoonedit";
import PrintProductbarcode from "./Component/BarCode/ProductBarcode/PrintProductbarcode";
import ProductbarPrint from "./Component/BarCode/ProductBarcode/ProductbarPrint";


import PrintSetbarcode from "./Component/BarCode/SetBarCode/PrintSetbarcode";
import Meetingcreate from "./Component/Master/Min of meeting/Meetingcreate";
import Meetingview from "./Component/Master/Min of meeting/Meetingview";
 /* Contract Management */
import ContractManagementTable from './Component/Contract Management/Table';
import ContractManagementCreate from './Component/Contract Management/Create';
import ContractManagementUpdate from './Component/Contract Management/Update';
import ContractManagementView from './Component/Contract Management/View';
/* Assest */
import AssestTable from './Component/Staff Assests/Assest/Table';
import AssestCreate from './Component/Staff Assests/Assest/Create';
import AssestUpdate from './Component/Staff Assests/Assest/Update';
import AssestView from './Component/Staff Assests/Assest/View';
/* Staff Assests */
import StaffAssestTable from './Component/Staff Assests/Staff Assest/Table';
import StaffAssestCreate from './Component/Staff Assests/Staff Assest/Create';
import StaffAssestUpdate from './Component/Staff Assests/Staff Assest/Update';
import StaffAssestView from './Component/Staff Assests/Staff Assest/View';
import AssignToStaffTable from './Component/Staff Assests/AssignToStaff/Table';
import AssignToStaffCreate from './Component/Staff Assests/AssignToStaff/Create';
import AssignToStaffUpdate from './Component/Staff Assests/AssignToStaff/Update';
import AssignToStaffView from './Component/Staff Assests/AssignToStaff/View';
import JobsheetInwardcreate from "./Component/Jobsheet/JobsheetinWard/JobsheetInwardcreate";
// import Inwardedit from "./Component/Jobsheet/JobsheetinWard/Inwardedit";
import Inwardtable from "./Component/Jobsheet/JobsheetinWard/Inwardtable";
import Inwardview from "./Component/Jobsheet/JobsheetinWard/Inwardview";
// import Inwardedit from "./Component/Jobsheet/JobsheetinWard/Inwardedit2";
import Jobsheetoutwardcreate from "./Component/Jobsheet/JObsheetoutward/Jobsheetoutwardcreate";
import Outwardtable from "./Component/Jobsheet/JObsheetoutward/Outwardtable";
import Outwardview from "./Component/Jobsheet/JObsheetoutward/Outwardview";
import Outwardedit from "./Component/Jobsheet/JObsheetoutward/Outwardedit";
import Inwardedit2 from "./Component/Jobsheet/JobsheetinWard/Inwardedit2";
import EWayBillPrint from "./Component/Sales/E-Way Bill Print";
import InvoicePrint from "./Component/Sales/InvoicePrint";
import MapView from "./Component/Master/Staff/MapView";
import Dismintable from "./Component/Master/Distributor Min stock/Dismintable";
import Disminupdate from "./Component/Master/Distributor Min stock/Disminupdate";
import Dmincreate from "./Component/Master/Distributor Min stock/Dmincreate";
import DistrubutorMinsotckcreate from "./Component/Reports/DistrubutorMinsotck/DistrubutorMinsotckcreate";

/** Grouping Module */
/** Group1 Module */
import Group1Table from './Component/Grouping/Group1/Table';
import Group1Create from './Component/Grouping/Group1/Create';
import Group1Update from './Component/Grouping/Group1/Update';
import Group1View from './Component/Grouping/Group1/View';
/** Group2 Module */
import Group2Table from './Component/Grouping/Group2/Table';
import Group2Create from './Component/Grouping/Group2/Create';
import Group2Update from './Component/Grouping/Group2/Update';
import Group2View from './Component/Grouping/Group2/View';
/** Group3 Module */
import Group3Table from './Component/Grouping/Group3/Table';
import Group3Create from './Component/Grouping/Group3/Create';
import Group3Update from './Component/Grouping/Group3/Update';
import Group3View from './Component/Grouping/Group3/View';
/** Group4 Module */
import Group4Table from './Component/Grouping/Group4/Table';
import Group4Create from './Component/Grouping/Group4/Create';
import Group4Update from './Component/Grouping/Group4/Update';
import Group4View from './Component/Grouping/Group4/View';
/** Group5 Module */
import Group5Table from './Component/Grouping/Group5/Table';
import Group5Create from './Component/Grouping/Group5/Create';
import Group5Update from './Component/Grouping/Group5/Update';
import Group5View from './Component/Grouping/Group5/View';
/** Group6 Module */
import Group6Table from './Component/Grouping/Group6/Table';
import Group6Create from './Component/Grouping/Group6/Create';
import Group6Update from './Component/Grouping/Group6/Update';
import Group6View from './Component/Grouping/Group6/View';
/** Group7 Module */
import Group7Table from './Component/Grouping/Group7/Table';
import Group7Create from './Component/Grouping/Group7/Create';
import Group7Update from './Component/Grouping/Group7/Update';
import Group7View from './Component/Grouping/Group7/View';
/** Group8 Module */
import Group8Table from './Component/Grouping/Group8/Table';
import Group8Create from './Component/Grouping/Group8/Create';
import Group8Update from './Component/Grouping/Group8/Update';
import Group8View from './Component/Grouping/Group8/View';
/** Group9 Module */
import Group9Table from './Component/Grouping/Group9/Table';
import Group9Create from './Component/Grouping/Group9/Create';
import Group9Update from './Component/Grouping/Group9/Update';
import Group9View from './Component/Grouping/Group9/View';
/** Group10 Module */
import Group10Table from './Component/Grouping/Group10/Table';
import Group10Create from './Component/Grouping/Group10/Create';
import Group10Update from './Component/Grouping/Group10/Update';
import Group10View from './Component/Grouping/Group10/View';

/** Voucher Module */
import VoucherTable from './Component/Voucher/Table';
import VoucherCreate from './Component/Voucher/Create';
import VoucherUpdate from './Component/Voucher/Update';
import VoucherView from './Component/Voucher/View';

/** Distributor Inword Module */
import DistInWordTable from './Component/Master/DistInWord/Table';
import DistInWordCreate from './Component/Master/DistInWord/Create';
import DistInWordUpdate from './Component/Master/DistInWord/Update';


import Meettable from "./Component/Master/Min of meeting/Meettable";
import Meetingedit from "./Component/Master/Min of meeting/Meetingedit";
import RepackingPrint from './Component/BarCode/Repacking/Print';
import RepackingCreate from './Component/BarCode/Repacking/Create';
import { FaRegUser } from "react-icons/fa";
import { RiLogoutCircleRLine } from "react-icons/ri";
import GalaTable from './Component/BarCode/Gala/Table';
import GalaCreate from './Component/BarCode/Gala/Create';
import GalaUpdate from './Component/BarCode/Gala/Update';
import GalaView from './Component/BarCode/Gala/View';
import Contract from "./Component/Reports/Contract";
import Soreport from "./Component/Reports/Soreport";
import Warehousestockreport from "./Component/Reports/Warehousestockreport";
import Discreate from "./Component/Reports/Disupload/Discreate";
import Distable from "./Component/Reports/Disupload/Distable";
import Losecreate from "./Component/BarCode/Losepacking/Losecreate";
import Loseprint from "./Component/BarCode/Losepacking/Loseprint";
import SetPrint from "./Component/BarCode/SetBarCode/SetPrint";
import Disinwardcreate from "./Component/Master/DistInWord/Disinwardcreate";
import DIsinwardupdate from "./Component/Master/DistInWord/DIsinwardupdate";
import DIsinwardview from "./Component/Master/DistInWord/DIsinwardview";
import Staffmap from "./Component/Master/Staff/Staffmap";
import Staffmapdis from "./Component/Master/Staff/Staffmapdis";
import Transportcreate from "./Component/Master/Transport/Transportcreate";
import Transporttable from "./Component/Master/Transport/Transporttable";
import Loseetable from "./Component/BarCode/Losepacking/Loseetable";
import Repackingtable from "./Component/BarCode/Repacking/Repackingtable";

// Staffmapdis
const App = () => {
  // const location = useLocation();

  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [userog, setuser] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const[allData,setAllData] = useState([]);
  const navigate = useNavigate();
  const handleLogout = () => {

    AuthService.logout();
    setShowModeratorBoard(false);
    setShowAdminBoard(false);
    setCurrentUser(undefined);
    setIsLoggedIn(false);
  }

  const location = useLocation();
  useEffect(() => {
    if (localStorage.getItem('user') !== null) {
      axios.get(BASE_URL + 'notification/login/getAll',{
        headers:authHeader()
      }).then(res=>{
       setAllData(res.data)
      })
    }
    const formatTimeLeft = (timeLeft) => {
      const seconds = Math.floor((timeLeft / 1000) % 60);
      const minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
      const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
    
      return `${hours}h ${minutes}m ${seconds}s`;
    };
    const handleEvent = (event) => {
      // console.log("add")
      const token = JSON.parse(localStorage.getItem('user'));

      if (token && token.token) {
        try {
          const decode = jwtDecode(token.token);
          const exp = decode.exp * 1000;  
          const currentTime = new Date().getTime();
          const timeLeft = exp - currentTime;

          if (exp < currentTime) {
             logOut();
            navigate('/');
            // con/sole.log("Token has expired.");
           } else {
            // console.log("Token is valid. Time left:", );
            // console.log("Token is valid.");
           }
        } catch (error) {
          // console.error('Error decoding token:', error);
        }
      } else {
       
    if(location.pathname!=="/"){

      // console.log("working one 1"+location.pathname)
                                                  
      logOut1();

                  navigate('/');

        // console.log('Token not found or invalid.');
      }
      
      else{
        // window.location.reload();

        logOut1();
        navigate('/');

        // console.log("working one 2"+location.pathname)

      }
    
    }
    };

    //  document.addEventListener('click', handleEvent);
    // document.addEventListener('keydown', handleEvent);
    // document.addEventListener('mousemove', handleEvent);
 
    

    Aos.init({
      offset: 100,
      duration: 200,
      easing: 'ease-in-sine',
      delay: 100,
    });
 

 
    if (localStorage.getItem('user') == null) {

    } else {
      const username = JSON.parse(localStorage.getItem('user')).username
      console.log(localStorage.getItem('user'))
      const capital = (username)

      capital.toUpperCase()

      setuser(capital.toUpperCase()[0]);
    }




    const user = AuthService.getCurrentUser();

    if (user) {
       setCurrentUser(user.token);
      setShowModeratorBoard(user.roles.includes("ROLE_MODERATOR"));
      setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      // document.removeEventListener('click', handleEvent);
      // document.removeEventListener('keydown', handleEvent);
      // document.removeEventListener('mousemove', handleEvent);
      EventBus.remove("logout");
    };



  }, [location]);
useEffect(() => {

  const token = JSON.parse(localStorage.getItem('user'));

  if (token && token.token) {
    try {
      const decode = jwtDecode(token.token);
      const exp = decode.exp * 1000;  
      const currentTime = new Date().getTime();
      const timeLeft = exp - currentTime;
      console.log(timeLeft)
      setTimeout(myGreeting, timeLeft);

      if (exp < currentTime) {
         logOut();
        navigate('/');
        // con/sole.log("Token has expired.");
       } else {
        // console.log("Token is valid. Time left:", );
        // console.log("Token is valid.");
       }
    } catch (error) {
      // console.error('Error decoding token:', error);
    }
  }else{
    if (localStorage.getItem('user') !== null) {
      setTimeout(myGreeting, 3000);
      
    //   alert(localStorage.getItem('user') !== null)
    }
  }



 
}, [])
const myGreeting=()=>{
  logOut();
  navigate('/');
  console.log(localStorage.getItem('user') !== null)

}
useEffect(() => {
  if (localStorage.getItem('user') !== null) {

  }
}, [ ])

  // useEffect(() => {
  //   // Fetch user data and notifications
  //   const fetchData = async () => {
  //     if (localStorage.getItem('user') !== null) {
  //       try {
  //         const response = await axios.get(BASE_URL + 'notification/login/getAll', {
  //           headers: authHeader()
  //         });
  //         setAllData(response.data);

  //         const token = JSON.parse(localStorage.getItem('user')).token;

  //         if (token) {
  //           const decode = jwtDecode(token);
  //           const exp = decode.exp * 1000;
  //           const currentTime = new Date().getTime();

  //           if (exp < currentTime) {
  //             logOut();
  //             navigate('/');
  //           } else {
  //             // Token is valid; you can add additional logic if needed
  //           }
  //         }
  //       } catch (error) {
  //         console.error('Error fetching data:', error);
  //       }
  //     } else {
  //       // window.location.reload()
  //       logOut1();
  //       navigate('/');
  //     }
  //   };

  //   fetchData();

  //   Aos.init({
  //     offset: 100,
  //     duration: 200,
  //     easing: 'ease-in-sine',
  //     delay: 100,
  //   });

  //   const currentUser = AuthService.getCurrentUser();
  //   if (currentUser) {
  //     setCurrentUser(currentUser.token);
  //     setShowModeratorBoard(currentUser.roles.includes('ROLE_MODERATOR'));
  //     setShowAdminBoard(currentUser.roles.includes('ROLE_ADMIN'));
  //     const username = JSON.parse(localStorage.getItem('user')).username;
  //     const capital = username.toUpperCase();
  //     setuser(capital[0]);
  //   }

  //   const interval = setInterval(() => {
  //     if (localStorage.getItem('user')) {
  //       const token = JSON.parse(localStorage.getItem('user')).token;
  //       if (token) {
  //         const decode = jwtDecode(token);
  //         const exp = decode.exp * 1000;
  //         const currentTime = new Date().getTime();

  //         if (exp < currentTime) {
  //           logOut();
  //           navigate('/');
  //         }
  //       }
  //     }
  //   }, 10000);  

  //   EventBus.on('logout', () => {
  //     logOut();
  //   });

  //   return () => {
  //     clearInterval(interval);
  //     EventBus.remove('logout');
  //   };
  // }, [location, navigate]);




  const logOut = () => {

    localStorage.removeItem("user");

    AuthService.logout();
    setShowModeratorBoard(false);
    setShowAdminBoard(false);
    setCurrentUser(undefined);
  };
  const logOut1 = () => {

    localStorage.removeItem("user");

    AuthService.logout1();
    setShowModeratorBoard(false);
    setShowAdminBoard(false);
    setCurrentUser(undefined);
  };

  const handle = useFullScreenHandle();

  const [menuCollapse, setMenuCollapse] = useState(false)


  const menuIconClick = () => {

    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  const [isVisible, setIsVisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);

  const notificationdelete = (e) => {
    setIsVisible(!isVisible);
    axios.put(BASE_URL + 'notification/login/put/'+e.id,{},{
      headers:authHeader()
    }).then(res=>{
      if(res.data){
         if(e.p_id !== 0 ){
          navigate('/PrimaryOrderView/'+ e.p_id + '/'+'pointindex')
         } else if(e.w_id !== 0){
          navigate('/workorderview/'+ e.w_id + '/'+'pointindex')
         } else if(e.dc_id !== 0){
          navigate('/DeliveryChallanView/'+ e.dc_id + '/'+'pointindex')
         } else if(e.so_id !== 0){
          navigate('/SalesOrderView/'+ e.so_id + '/'+'pointindex')
         }
      }

    })

  }
  
  // useEffect(()=>{
  //   const user = JSON.parse(localStorage.getItem('user'));
  //   if(user.token){
  //     setIsLoggedIn(true)
  //     const token = user.token;
  //     const decode = jwtDecode(token);
      
  //     const exp = decode.exp * 1000;
  //     let datenow = new Date(exp);
  //     if(exp < datenow.getTime()){
  //       navigate('/');
  //       setIsLoggedIn(false)
  //     }

  //   }
  // },[])
  function GFG_Fun() {
    let inputs = document.querySelectorAll("input");
  
    inputs.forEach(function(input) {
      input.addEventListener("mousewheel", function (event) {
        this.blur();
      });
    });
  
    // result.innerHTML = "Mouse wheel disabled!";
  }
  document.addEventListener("mousewheel", () => {
    GFG_Fun();
  
  });
  
  
  GFG_Fun();






  const user = AuthService.getCurrentUser();
  return (
    <div className="app">
      {isLoggedIn ? (
        <FullScreen handle={handle}>
          <Logout timeout={86400000} onLogout={handleLogout} />
          {currentUser && (
            <div className="header">

              <nav class="navbar" id="naving">
                <div class="">




                  <form class="d-flex" role="search" >
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8" /><line x1="21" y1="21" x2="16.65" y2="16.65" /></svg> */}
                    {/* <input type=" text" id=" " className='ser' placeholder='Search' /> */}
                    <BiBell size={24} color="#164194" style={{width:24,height:24}} onClick={() => setIsVisible((prev) => !prev)}/>
                    <div style={{fontSize:10,marginLeft:-33,marginTop:-5,fontWeight:600,background:'red',padding:'3px 3px',height:18,borderRadius:'3px',color:'white'}}>{allData.length}</div>
                    {isVisible && (
          <div class="notification-hub">
            <header>
            <span>Notification</span>
              {/* <NotificationCount /> */}
            </header>
            <div className="card scrolling">
            <ul>
              {allData.map(item=>(
                <li onClick={(e) => notificationdelete(item)}>{item.status}&nbsp;at&nbsp;{new Date(item.createdAt).toLocaleDateString()}</li>
              ))}
         
     </ul></div>

            
            </div>
                    )}
                     <a
                      class="d-flex align-items-center hidden-arrow colorname"
                      // href="/"
                      id="navbarDropdownMenuAvatar"
                      role="button"
                      onClick={() => setIsVisible1((prev) => !prev)}
                      style={{color:'white',padding:'0px 10px'}}
                    >
                      {userog}
                       {isVisible1 && (
              <div class="notification-hub1" >
            <div class="position-relative px-4 pt-3 pb-2" style={{height:'205px',background:'white',}}>
              

              <div class="d-flex align-items-center mb-3 pb-3 border-bottom gap-6">
                <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" class="rounded-circle" width="54" height="54" alt="matdash-img" />
                
              <div style={{textAlign:'left',marginLeft:'8px'}}>

              <p class="mb-0 text-dark">{user.username}</p>
              <h5 class="mb-0 fs-6" style={{fontWeight:400}}>{user.email}</h5>
              </div>
              </div>
              <a style={{alignItems:'left',textAlign:'left',marginLeft:'8px',marginTop:'3px'}}
                      href={user.roles[0] === "ROLE_ADMIN" && "/sedit/"+user.id+'/1' || user.roles[0] === "ROLE_ASE" && "/sedit/"+user.id+'/1' ||  user.roles[0] === "ROLE_ASM" && "/sedit/"+user.id+'/1' ||  user.roles[0] === "ROLE_RSM" && "/sedit/"+user.id+'/1' ||  user.roles[0] === "ROLE_NSM" && "/sedit/"+user.id+'/1' ||  user.roles[0] === "ROLE_RETAILER" && "/retaileredit/"+user.id+'/1' ||  user.roles[0] === "ROLE_DISTRIBUTOR" && "/distrubutoredit/"+user.id+'/1'||  user.roles[0] === "ROLE_SUPPLIER" && "/SupplierUpdate/"+user.id+'/1'}
                      class="p-2 dropdown-item h6 rounded-1"
                      role="button"
                      // onClick={logOut}
                    >
                <span style={{fontWeight:400}}><FaRegUser size={28} style={{width:20,height:20}} /><span style={{marginLeft:'5px',fontSize:'16px',}}>Profile Update</span></span>
                </a>
              <a style={{alignItems:'left',textAlign:'left',marginLeft:'8px'}}
                      href="/"
                      class="p-2 dropdown-item h6 rounded-1"
                      role="button"
                      onClick={logOut}
                    >
                <span style={{fontWeight:400}}><RiLogoutCircleRLine size={28}  style={{width:22,height:22}}/><span style={{marginLeft:'5px',fontSize:'16px',}}>Sign Out</span></span>
                </a>
              
           </div>
            </div>)}


                    </a>

                  </form>
                </div>
              </nav>

            </div>
          )}

          {currentUser ? (
            <div className="cccc" onClick={()=>{setIsVisible(false);setIsVisible1(false)}}>
              <Routes><Route path="/un" element={<Under12 />} />


              </Routes>

              <SideBar>

                <Routes>
                  <Route path="/DashBoard"  element={<DashBoard />} />

                  <Route path="/" element={<DashBoard />} />
                  {/* <Route path="/Login" element={<Login />} /> */}
                  {/* <Route path="/" element={<Login />} /> */}
                  <Route path="/company" element={<Company />} />

                  <Route path="/Company" element={<Company />} />
                  {/* <Route path="/product" element={<PTable />} /> */}
                  <Route path="/pcreate" element={<PCreate />} />
                  <Route path="/pview/:id/:pointindex" element={<PView />} />
                  <Route path="/pedit/:id/:pointindex" element={<PEdit />} />
                  <Route path="pview/:id" element={<Pview />} />
                  <Route path="/pview11/:id/:pointindex" element={<PView11 />} />
                  {/* <Route path="/wokring" element={<Under/>} /> */}
                  <Route path="/Loseprint/:id" element={<Loseprint/>} />
                  <Route path="/ProductbarPrint/:id/:size" element={<ProductbarPrint/>} />
                  {/* PrintProductbarcode */}
                  <Route path="/PrimaryOrderprint/:id" element={<Print />} />
                  <Route path="/cr" element={<Create1 />} />
                  <Route path="/Losecreate" element={<Losecreate />} />
                  
                  <Route path="/Warehousestockreport/:pointindex" element={<Warehousestockreport />} />
                  
                  {/* <Route path="/" element={<Brand />} /> */}
                  <Route path="/brand/:pointindex" element={<Newbrand />} />
                  <Route path="/Discreate" element={<Discreate/>} />
                  <Route path="/Distable/:pointindex" element={<Distable/>} />
                   
                  <Route path="/brandcreate" element={<BrandCreate />} />
                  <Route path="/brandedit/:id/:pointindex" element={<BrandEdit />} />
                  {/* <Route path="/warehouse" element={<WareTable />} /> */}
                  <Route path="/warehouse/:pointindex" element={<Newwarehouse />} />
                  
                  <Route path="/wareCreate" element={<WareHouse />} />
                  <Route path="/wareEdit/:id/:pointindex" element={<WareEdit />} />
                  <Route path="/wareview/:id/:pointindex" element={<WareView />} />
                  <Route path="/staff" element={<STable />} />
                  <Route path="/screate" element={<Staff />} />
                  <Route path="/sedit/:id/:pointindex" element={<SEdit />} />
                  <Route path="/sview/:id/:pointindex" element={<Map />} />
                  {/* <Route path="/maptable/:id/:pointindex" element={<MapTable />} /> */}
                  <Route path="/dashboard" element={<DashBoard />} />
                  <Route path="/retailercreate" element={<RetailerCreate />} />
                  {/* <Route path="/retailertable" element={<RetailerTable/>}/> */}
                  <Route path="/retaileredit/:id/:pointindex" element={<RetailerEdit />} />
                  <Route path="/retailerview/:id/:pointindex" element={<RetailerView />} />
                  {/* <Route path="/distrbutortable" element={<DistrbutorTable/>}/> */}
                  <Route path="/distrbutorcreate" element={<DistrbutorCreate />} />
                  <Route path="/distrubutoredit/:id/:pointindex" element={<DistrbutorEdit />} />
                  <Route path="/distrubutorview/:id/:pointindex" element={<DistrbutorView />} />
                  <Route path="/workordercreate" element={<WorkOrderCreate />} />
                  <Route path="/workorderedit/:id/:pointindex" element={<WorkOrderEdit />} />
                  <Route path="/workorderview/:id/:pointindex" element={<WorkOrderView />} />
                  <Route path="/workordertable" element={<WorkOrderTable />} />
                  {/* <Route path="/PrimaryOrderTable" element={<PrimaryOrderTable/>}/> */}
                  <Route path="/PrimaryOrderCreate" element={<PrimaryOrderCreate />} />
                  <Route path="/PrimaryOrderView/:id/:pointindex" element={<PrimaryOrderView />} />
                  {/* <Route path="/temporaryretailer" element={<Table />} /> */}
                  <Route path="/PrimaryOrderUpdate/:id/:pointindex" element={<PrimaryOrderUpdate />} />

                  <Route path="/temporaryretailer/:pointindex" element={<Newtem />} />

                  <Route path="/temporaryview/:id/:pointindex" element={<View />} />
                  <Route path="/temporarycreate/:id" element={<Create />} />
                  <Route path="/product1/:pointindex1" element={<New />} />
                  <Route path="/the" element={<New2 />} />
                  <Route path="/PrimaryOrderTable" element={<Ptable />} />
                  <Route path="/distrbutortable" element={<Tabledis />} />
                  <Route path="/retailertable" element={<Retable />} />
                  {/* <Route path='/sprint' element={<Salesorder />} /> */}
                  {/* <Route path='/del' element={<DeliveryNote />} /> */}
                  <Route path='/formattax' element={<InvoiceFormat />} />

                  <Route path='/sprint/:id' element={<Salesorder />} />
                  <Route path='/del/:id' element={<DeliveryNote />} />
                  <Route path='/ReceiptNote/:id' element={<ReceiptNote />} />
                  <Route path="/Loseetable/:pointindex" element={<Loseetable />} />
                  <Route path="/Repackingtable/:pointindex" element={<Repackingtable />} />

                   
                  {/* <Route path='/ReceiptNote' element={<ReceiptNote />} /> */}
                  <Route path="/Purchaseorder" element={<Purchaseorder />} />
                  <Route path="/INvoice" element={<INvoice />} />
                  <Route path="/mysalesid/:id" element={<Salesreturn />} />
                  <Route path="/Purchasereturn" element={<Purchasereturn />} />
                  <Route path="/Stafftable" element={<Stafftable />} />
                  {/* <Route path="/Brandtable" element={<Brandtable />} /> */}
                  {/* <Route path="/Brandtable" element={<Newbrand />} /> */}
                  <Route path="/Temtable" element={<Temtable />} />
                  <Route path="/Worktablett" element={<Worktable />} />
                  <Route path="/product/:pointindex" element={<Newtable />} />
                  <Route path="/Stafftable/:pointindex" element={<Newstafftable />} />
                  <Route path="/Newretailer/:pointindex" element={<Newretailer />} />
                  <Route path="/Newoo/:pointindex" element={<Newwo />} />
                  <Route path="/Newpo/:pointindex" element={<Newpo />} />
                  <Route path="/Newdis/:pointindex" element={<Newdis />} />
                  <Route path="/maptable/:id/:pointindex" element={<MapView />} />
                  {/* <Route path="/SupplierTable" element={<SupplierTable />} /> */}
                  <Route path="/SupplierTable/:pointindex" element={<Newsupplier />} />
                  <Route path="/SupplierCreate" element={<SupplierCreate />} />
                  <Route path="/SupplierUpdate/:id/:pointindex" element={<SupplierEdit />} />
                  <Route path="/SupplierView/:id/:pointindex" element={<SupplierView />} />
                  <Route path="/Supplier/Contact/:id" element={<SupplierContact />} />
                  
                  <Route path="/potable/:pointindex" element={<Newpurchaseorder />} />
                  {/* <Route path="/potable" element={<POTable />} /> */}
                  <Route path="/pocreate" element={<POCreate />} />
                  <Route path="/poedit/:id/:pointindex" element={<POEdit />} />
                  <Route path="/po/:id/:pointindex" element={<POView />} />
                  <Route path="/PurchaseTable" element={<PurchaseTable />} />
                  <Route path="/PurchaseCreate12/:id" element={<PurchaseCreate />} />
                  <Route path="/PurchaseCreate12" element={<PurchaseCreate1 />} />
                  <Route path="/PurchaseEdit/:id/:pointindex" element={<PurchaseEdit />} />
                  <Route path="/PurchaseView/:id/:pointindex" element={<PurchaseView />} />
                  {/* <Route path="/SupplierDeliveryNoteTable/:pointindex" element={<SupplierDeliveryNoteTable />} /> */}
                  <Route path="/SupplierDeliveryNoteTable/:pointindex" element={<Newdel />} />

                  <Route path="/Transportcreate" element={<Transportcreate />} />
                  <Route path="/Transporttable/:pointindex" element={<Transporttable/>} />

                  
                  <Route path="/SupplierDeliveryNoteCreate/:id" element={<SupplierDeliveryNoteCreate />} />
                  <Route path="/SupplierDeliveryNoteEdit/:id/:pointindex" element={<SupplierDeliveryNoteEdit />} />
                  <Route path="/SupplierDeliveryNoteView/:id/:pointindex" element={<SupplierDeliveryNoteView />} />
                  {/* <Route path="/MaterialReceiptNoteTable" element={<MaterialReceiptNoteTable />} /> */}
                  <Route path="/MaterialReceiptNoteTable/:pointindex" element={<Newmrn />} />


                  <Route path="/MaterialReceiptNoteCreate/:id" element={<MaterialReceiptNoteCreate />} />
                  <Route path="/MaterialReceiptNoteCreate" element={<MaterialReceiptNoteCreate1 />} />
                  <Route path="/MaterialReceiptNoteEdit/:id/:pintindex" element={<MaterialReceiptNoteEdit />} />
                  <Route path="/MaterialReceiptNoteView/:id/:pointindex" element={<MaterialReceiptNoteView />} />
                  {/* <Route path="/PurchaseReturnTable" element={<PurchaseReturnTable />} /> */}
                  <Route path="/PurchaseReturnTable/:pointindex" element={<Newpurchasereturn />} />


                  <Route path="/PurchaseReturnCreate" element={<PurchaseReturnCreate />} />
                  <Route path='/PurchaseReturenUpdate/:id/:pointindex' element={<PurchaseReturnUpdate/>}/>
                  <Route path="/PurchaseReturnView/:id/:pointindex" element={<PurchaseReturnView />} />
                  {/* <Route path="/SalesOrderTable" element={<SalesOrderTable />} /> */}
                  <Route path="/SalesOrderTable/:pointindex" element={<Newsalesorder />} />

                  <Route path="/SalesOrderCreate/:id" element={<SalesOrderCreate />} />
                  <Route path="/WorkOrderToSalesOrder/:id" element={<WorkorderToSalesorder/>}/>
                  <Route path="/SalesOrderCreate" element={<SalesOrderCreate1 />} />
                  <Route path="/SalesOrderEdit/:id/:pointindex" element={<SalesOrderEdit />} />
                  <Route path="/SalesOrderView/:id/:pointindex" element={<SalesOrderView />} />
                  {/* <Route path="/DeliveryChallanTable" element={<DCTable />} /> */}
                  

                  <Route path="/OpenCreate/:pointindex" element={<Newopening />} />
                  <Route path="/minimumstock/:pointindex" element={<MinimumStock />} />
               
                  <Route path="/Contract/:pointindex" element={<Contract/>} />

                  

                  <Route path="/DeliveryChallanTable/:pointindex" element={<Newdc />} />
                  <Route path="/DeliveryChallanCreate/:id" element={<DCCreate />} />
                  <Route path="/RetailerDeliverychallanCreate/:id" element={<RetailerCreateDC />} />
                  <Route path="/DeliveryChallanCreate" element={<DCCreate1 />} />
                  <Route path="/DeliveryChallanView/:id/:pointindex" element={<DCView />} />
                  <Route path="/DeliveryChallanCreatesopen" element={<DC1Create />} />
                  {/* <Route path="/Branch/Table" element={<CBranch />} /> */}

                  <Route path="/Branch/Table/:pointindex" element={<Newbranch />} />

                  
 
                  <Route path="/Branch/Create" element={<CompanyBranchCreate />} />
                  <Route path="/Branch/Edit/:id/:pointindex" element={<CompanyBranchEdit />} />
                  <Route path="/Branch/View/:id/:pointindex" element={<CompanyBranchView />} />
                  {/* <Route path="/Sales/Table" element={<SalesTable />} /> */}
                  <Route path="/Sales/Table/:pointindex" element={<Newsales/>} />


                  <Route path="/Sales/Create/:id" element={<SalesCreate />} />
                  <Route path="/RetailerSales/Create/:id" element={<RetailerCreatesales />} />
                  <Route path="/Sales/Create" element={<SalesCreate1 />} />
                  <Route path="/Sales/Update/:id/:pointindex" element={<SalesEdit />} />
                  <Route path="/Sales/View/:id/:pointindex" element={<SalesView />} />
                  <Route path="/E-Way-Bill-Print/:id" element={<EWayBillPrint/>}/>
                  <Route path="/InvoicePrint/:id" element={<InvoicePrint />} />
                  <Route path="/salesreturn/:pointindex" element={<Newsalestable />} />
                  <Route path="/SalesReturnCreate" element={<SalesReturnCreate />} />
                  <Route path="/SalesReturnUpdate/:id/:pointindex" element={<SalesReurnUpdate/>}/>
                  <Route path="/SalesReturnView/:id/:pointindex" element={<SalesRetunView />} />
                  <Route path="/DistributorReport" element={<DistributorReport />} />
                  <Route path="/RetailerReport" element={<RetailerReport />} />
                  <Route path="/Newpurchase/:pointindex" element={<Newpurchase />} />
                  <Route path="/Openingedit/:id/:pointindex" element={<Openingedit />} />
                  <Route path="/OpenCreat" element={<OpenCreate />} />
                  <Route path="/OpenCreate/:pointindex" element={<OpenCreate />} />
                  <Route path="/Minstockreport/:pointindex" element={<Minstock />} />
                  <Route path="/Minstockindex/:pointindex" element={<MinimumStock />} />
                  <Route path="/Soreport" element={<Soreport/>} />

                  
                  <Route path="/DistributorStockrp" element={<DistributorStockrp />} />
                  <Route path="/Outwarddis/:pointindex" element={<Outwarddis />} />
                  <Route path='/Distributor/Outward/Table/:pointindex' element={<Distoutwordtable/>}/>
                  <Route path='/Distributor/OutWord' element={<Disoutwordcreate/>}/>
                  <Route path='/DistributorOutWardUpdate/:id/:pointindex' element={<Disoutwardupdate/>}/>
                  <Route path='/GodownStockReport/:pointindex' element={<GodownStockReport/>}/>
                  <Route path="/PendingOrderVsGodownStockComponents/:pointindex" element={<PendingOrderVsGodownStockComponents/>}/>
                  <Route path='/Attendance/Page/:pointindex' element={<AttendanceCreate/>}/>
                  <Route path="/AttendanceCreate" element={<Attendace_Create/>}/>
                  <Route path="/Attendance/Table/:pointindex" element={<AttendanceTable/>}/>
                  <Route path="/HolidayTable/:pointindex" element={<HolidayTable/>}/>
                  <Route path="/HolidayCreate" element={<HolidayCreate/>}/>
                  <Route path="/HolidayUpdate/:id/:pointindex" element={<HolidayUpdate/>}/>
                  <Route path="/HolidayView/:id/:pointindex" element={<HolidayView/>}/>
                  <Route path='/SecondaryOrderReports/:pointindex' element={<SecondaryOrderReports/>}/>
                  <Route path='/PrimaryOrderReports/:pointindex' element={<Primaryorderreports/>}/>
                  <Route path='/SalesExpenseReports/:pointindex' element={<SalesExpenseReports/>}/>
                  <Route path="/Erpmobilebanner345" element={<MobileProductlunch/>} />
                  <Route path="/Arealaunchmobile" element={<Arealaunchmobile/>} />
                  <Route path='/Assambly/Create' element={<AssamblyCreate/>}/>
                  <Route path="/BOM/Table/:pointindex" element={<BomTable/>}/>
                  <Route path="/BOM/Create" element={<BomCreate/>}/>
                  <Route path="/BOM/View/:id/:pointindex" element={<BomView/>}/>
                  <Route path="/ProductBarCode/Table/:pointindex" element={<ProductBarCodeTable/>}/>
                  <Route path="/ProductBarCode/Create" element={<ProductBarCodeCreate/>}/>
                  {/* <Route path="/ProductBarCode/Print/:id/:pointindex" element={<ProductBarCodePrint/>}/> */}
                  <Route path='/PrintProductBarcode/:pointindex' element={<PrintProductbarcode/>}/>
                  <Route path="/PrintSetbarcode/:pointindex" element={<PrintSetbarcode/>}/>
                  <Route path="/SetProductBarCode/Table/:pointindex" element={<SetBarCodeTable/>}/>
                  <Route path="/SetProductBarCode/Create" element={<SetBarCodeCreate/>}/>
                  {/* <Route path="/SetProductBarCode/View/:id/:piointindex" element={<SetBarCodeView/>}/> */}
                  <Route path="/CartonBarCode/Table/:pointindex" element={<CartonBarCodeTable/>}/>
                  <Route path="/CartonBarCode/Create" element={<CartonBarCodeCreate/>}/>
                  <Route path="/Printpageone/:id" element={<CartonBarCodePrint/>}/>
                  <Route path="/Cartoonview/:id/:pointindex" element={<Cartoonview/>}/>
                  <Route path="/MasterCarton/Table/:pointindex" element={<MasterCartonTable/>}/>
                  <Route path="/MasterCarton/Create" element={<MasterCartonCreate/>}/>
                  <Route path="/Mastercartoonview/:id/:pointindex" element={<Mastercartoonview/>}/>
                  <Route path="/Mastercartoon/Update/:id/:pointindex" element={<Mastercartoonedit/>}/>
                  <Route path="/Gala/Table/:pointindex" element={<GalaTable/>}/>
                  <Route path="/Gala/Create" element={<GalaCreate/>}/>
                  <Route path="/Gala/Update/:id/:pointindex" element={<GalaUpdate/>}/>
                  <Route path="/Gala/View/:id/:pointindex" element={<GalaView/>}/>

                  <Route path="/ContractManagement/Table/:pointindex" element={<ContractManagementTable/>}/>
                  <Route path="/ContractManagement/Create" element={<ContractManagementCreate/>}/>
                  <Route path="/ContractManagement/Update/:id/:pointindex" element={<ContractManagementUpdate/>}/>
                  <Route path="/ContractManagement/View/:id/:pointindex" element={<ContractManagementView/>}/>
                  <Route path="/Assest/Table/:pointindex" element={<AssestTable/>}/>
                  <Route path="/Assest/Create" element={<AssestCreate/>}/>
                  <Route path="/Assest/Update/:id/:pointindex" element={<AssestUpdate/>}/>
                  <Route path="/Assest/View/:id/:pointindex" element={<AssestView/>}/>
                  <Route path="/Asset Request Form/Table/:pointindex" element={<StaffAssestTable/>}/>
                  <Route path="/Asset Request Form/Create" element={<StaffAssestCreate/>}/>
                  <Route path="/Asset Request Form/Update/:id/:pointindex" element={<StaffAssestUpdate/>}/>
                  <Route path="/Asset Request Form/View/:id/:pointindex" element={<StaffAssestView/>}/>
                  <Route path="/AssignToStaffTable/:pointindex" element={<AssignToStaffTable/>}/>
                  <Route path="/AssignToStaffCreate" element={<AssignToStaffCreate/>}/>
                  <Route path="/AssignToStaffUpdate/:id/:pointindex" element={<AssignToStaffUpdate/>}/>
                  <Route path="/AssignToStaffView/:id/:pointindex" element={<AssignToStaffView/>}/>
                  
                  <Route path="/JobsheetInwardcreate/Create" element={<JobsheetInwardcreate/>}/>
                  <Route path="/inwardedit/:id/:pointindex" element={<Inwardedit2/>}/>
                  <Route path="/Inwardtable/:pointindex" element={<Inwardtable/>}/>
                  <Route path="/Inwardview/:id/:pointindex" element={<Inwardview/>}/>
                  <Route path="/Jobsheetoutwardcreate" element={<Jobsheetoutwardcreate/>}/>
                  <Route path="/Outwardtable/:pointindex" element={<Outwardtable/>}/>
                  <Route path="/Outwardview/:id/:pointindex" element={<Outwardview/>}/>
                  <Route path="/Outwardedit/:id/:pointindex" element={<Outwardedit/>}/>
                  <Route path="/Dismintable/:pointindex" element={<Dismintable/>}/>
                  <Route path="/Disminupdate/:id/:pointindex" element={<Disminupdate/>}/>
                  <Route path="/Dmincreate" element={<Dmincreate/>}/>
                  <Route path="/distributorminimumstockreport/:pointindex" element={<DistrubutorMinsotckcreate/>}/>
                   {/* Group1 Module  */}
                  <Route path="/Group1Table/:pointindex" element={<Group1Table/>}/>
                  <Route path="/group1Create" element={<Group1Create/>}/>
                  <Route path="/group1/Update/:id/:pointindex" element={<Group1Update/>}/>
                  <Route path="/group1/View/:id/:pointindex" element={<Group1View/>}/>
                   {/* Group2 Module  */}
                  <Route path="/group2Table/:pointindex" element={<Group2Table/>}/>
                  <Route path="/group2Create" element={<Group2Create/>}/>
                  <Route path="/group2/Update/:id/:pointindex" element={<Group2Update/>}/>
                  <Route path="/group2/View/:id/:pointindex" element={<Group2View/>}/>
                   {/* Group3 Module  */}
                  <Route path="/group3Table/:pointindex" element={<Group3Table/>}/>
                  <Route path="/group3Create" element={<Group3Create/>}/>
                  <Route path="/group3/Update/:id/:pointindex" element={<Group3Update/>}/>
                  <Route path="/group3/View/:id/:pointindex" element={<Group3View/>}/>
                  {/* Group4 Module  */}
                  <Route path="/group4Table/:pointindex" element={<Group4Table/>}/>
                  <Route path="/group4Create" element={<Group4Create/>}/>
                  <Route path="/group4/Update/:id/:pointindex" element={<Group4Update/>}/>
                  <Route path="/group4/View/:id/:pointindex" element={<Group4View/>}/>
                  {/* Group5 Module  */}
                  <Route path="/group5Table/:pointindex" element={<Group5Table/>}/>
                  <Route path="/group5Create" element={<Group5Create/>}/>
                  <Route path="/group5/Update/:id/:pointindex" element={<Group5Update/>}/>
                  <Route path="/group5/View/:id/:pointindex" element={<Group5View/>}/>
                  {/* Group6 Module  */}
                  <Route path="/group6Table/:pointindex" element={<Group6Table/>}/>
                  <Route path="/group6Create" element={<Group6Create/>}/>
                  <Route path="/group6/Update/:id/:pointindex" element={<Group6Update/>}/>
                  <Route path="/group6/View/:id/:pointindex" element={<Group6View/>}/>
                  {/* Group7 Module  */}
                  <Route path="/group7Table/:pointindex" element={<Group7Table/>}/>
                  <Route path="/group7Create" element={<Group7Create/>}/>
                  <Route path="/group7/Update/:id/:pointindex" element={<Group7Update/>}/>
                  <Route path="/group7/View/:id/:pointindex" element={<Group7View/>}/>
                  {/* Group8 Module  */}
                  <Route path="/group8Table/:pointindex" element={<Group8Table/>}/>
                  <Route path="/group8Create" element={<Group8Create/>}/>
                  <Route path="/group8/Update/:id/:pointindex" element={<Group8Update/>}/>
                  <Route path="/group8/View/:id/:pointindex" element={<Group8View/>}/>
                  {/* Group9 Module  */}
                  <Route path="/group9Table/:pointindex" element={<Group9Table/>}/>
                  <Route path="/group9Create" element={<Group9Create/>}/>
                  <Route path="/group9/Update/:id/:pointindex" element={<Group9Update/>}/>
                  <Route path="/group9/View/:id/:pointindex" element={<Group9View/>}/>
                  {/* Group10 Module  */}
                  <Route path="/group10Table/:pointindex" element={<Group10Table/>}/>
                  <Route path="/group10Create" element={<Group10Create/>}/>
                  <Route path="/group10/Update/:id/:pointindex" element={<Group10Update/>}/>
                  <Route path="/group10/View/:id/:pointindex" element={<Group10View/>}/>
                  {/* Voucher Module */}
                  <Route path="/Voucher/Table/:pointindex" element={<VoucherTable/>}/>
                  <Route path="/Voucher/Create" element={<VoucherCreate/>}/>
                  <Route path="/Voucher/Update/:id/:pointindex" element={<VoucherUpdate/>}/>
                  <Route path="/Voucher/View/:id/:pointindex" element={<VoucherView/>}/>
                  <Route path="/SetPrint/:id/:size" element={<SetPrint/>}/>

                  <Route path="/Disinwardcreate" element={<Disinwardcreate/>}/>
                  <Route path="/DIsinwardupdate/:id/:pointindex" element={<DIsinwardupdate/>}/>
                  <Route path="/DIsinwardview/:id/:pointindex" element={<DIsinwardview/>}/>
                  <Route path="/Disoutwordcreate" element={<Disoutwordcreate/>}/>
                  <Route path="/Staffmap" element={<Staffmap/>}/>
                  
                  <Route path="/Disoutwardupdate/:id/:pointindex" element={<Disoutwardupdate/>}/>

                  {/*  */}
                  {/* Distributor Inword Module */}
                  <Route path="/Distributor/Inward/Table/:pointindex" element={<DistInWordTable/>}/>
                  <Route path="/Distributor/Inward/Create" element={<DistInWordCreate/>}/>
                  <Route path="/Distributor/Inward/Update/:id/:pointindex" element={<DistInWordUpdate/>}/>
                  <Route path="/Staffmapdis" element={<Staffmapdis/>}/>


                  {/* Staffmapdis.js */}
                  <Route path="/Meetingcreate" element={<Meetingcreate/>}/>
                  <Route path="/Meetingview/:id/:pointindex" element={<Meetingview/>}/>
                  <Route path="/Meettable/:pointindex"  element={<Meettable/>}/>
                  <Route path="/Meetingedit/:id/:pointindex" element={<Meetingedit/>}/>
                  {/* Meetingedit */}
                  <Route path="/Repacking/Print/:pointindex" element={<RepackingPrint/>}/>
                  <Route path="/Repacking/Create" element={<RepackingCreate/>}/>
                  <Route
                    path="/MininmumStockReport/:pointindex"
                    element={<MininmumStockReport />}
                  />
                  <Route
                    path="/TransportReport/:pointindex"
                    element={<TransportReport />}
                  />

                  
                   <Route
                    path="/RetailerMonthReport/:pointindex"
                    element={<RetailerMonthReport />}
                  />

<Route
                    path="/DistributorMonthReport/:pointindex"
                    element={<DistributorMonthReport />}
                  />
                </Routes>
               
              </SideBar>
              <div class="footer" style={{ display: "none" }}>
                <span>Copyright @ 2023 <span class="text-danger">VM COMPUTE</span> All Rights Reserved</span>
              </div>
            </div>
          ) : (
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Login />} />

            </Routes>
          )}
        </FullScreen>
      ) : (
        <Login />
      )}
    </div>
  );
};

export default App;
